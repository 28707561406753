// @flow

import type { HighLightedPostType, SettingsType } from 'types/Settings';

export const APP_SET_SHOW_ALERT_INFO = 'APP_SET_SHOW_ALERT_INFO';
export const APP_SET_SHOW_HIGHLIGHTED_POST_MODAL = 'APP_SET_SHOW_HIGHLIGHTED_POST_MODAL';
export const APP_INCREASE_HIGHLIGHTED_POST_MODAL_COUNT = 'APP_INCREASE_HIGHLIGHTED_POST_MODAL_COUNT';
export const APP_RESET_HIGHLIGHTED_POST_MODAL_COUNT = 'APP_RESET_HIGHLIGHTED_POST_MODAL_COUNT';
export const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

export type APP_SET_SHOW_ALERT_INFO_ACTION = {
  type: 'APP_SET_SHOW_ALERT_INFO',
  payload: {
    showAlertInfo: string
  }
};

export type APP_SET_SHOW_HIGHLIGHTED_POST_MODAL_ACTION = {
  type: 'APP_SET_SHOW_HIGHLIGHTED_POST_MODAL',
  payload: {
    showHighlightedPostModal: HighLightedPostType
  }
};

export type APP_INCREASE_HIGHLIGHTED_POST_MODAL_COUNT_ACTION = {
  type: 'APP_INCREASE_HIGHLIGHTED_POST_MODAL_COUNT'
};

export type APP_RESET_HIGHLIGHTED_POST_MODAL_COUNT_ACTION = {
  type: 'APP_RESET_HIGHLIGHTED_POST_MODAL_COUNT'
};

export type FETCH_SETTINGS_START_ACTION = {
  type: 'FETCH_SETTINGS_START'
};

export type FETCH_SETTINGS_FAILURE_ACTION = {
  type: 'FETCH_SETTINGS_FAILURE'
};

export type FETCH_SETTINGS_SUCCESS_ACTION = {
  type: 'FETCH_SETTINGS_SUCCESS',
  payload: {
    settings: SettingsType
  }
};

export type Action =
  | APP_SET_SHOW_ALERT_INFO_ACTION
  | APP_SET_SHOW_HIGHLIGHTED_POST_MODAL_ACTION
  | APP_INCREASE_HIGHLIGHTED_POST_MODAL_COUNT_ACTION
  | APP_RESET_HIGHLIGHTED_POST_MODAL_COUNT_ACTION
  | FETCH_SETTINGS_START_ACTION
  | FETCH_SETTINGS_FAILURE_ACTION
  | FETCH_SETTINGS_SUCCESS_ACTION;

export function setShowAlertInfo(showAlertInfo: string): APP_SET_SHOW_ALERT_INFO_ACTION {
  return {
    type: APP_SET_SHOW_ALERT_INFO,
    payload: { showAlertInfo }
  };
}

export function setShowHighlightedPostModal(
  showHighlightedPostModal: HighLightedPostType
): APP_SET_SHOW_HIGHLIGHTED_POST_MODAL_ACTION {
  return {
    type: APP_SET_SHOW_HIGHLIGHTED_POST_MODAL,
    payload: { showHighlightedPostModal }
  };
}

export function increaseHightlighterPostModalCount(): APP_INCREASE_HIGHLIGHTED_POST_MODAL_COUNT_ACTION {
  return {
    type: APP_INCREASE_HIGHLIGHTED_POST_MODAL_COUNT
  };
}

export function resetHightlighterPostModalCount(): APP_RESET_HIGHLIGHTED_POST_MODAL_COUNT_ACTION {
  return {
    type: APP_RESET_HIGHLIGHTED_POST_MODAL_COUNT
  };
}

export function fetchSettings(): FETCH_SETTINGS_START_ACTION {
  return {
    type: FETCH_SETTINGS_START
  };
}

export function settingsFetched(settings: SettingsType): FETCH_SETTINGS_SUCCESS_ACTION {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    payload: { settings }
  };
}

export function settingsFetchError(): FETCH_SETTINGS_FAILURE_ACTION {
  return {
    type: FETCH_SETTINGS_FAILURE
  };
}
