// @flow
import React from 'react';

type Props = {
  content: any,
  hideModal: () => void
};

function NewsletterSubscriptionSuccessModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal__container col-md-6 col-lg-4">
      <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
        <i role="button" tabIndex={0} className="icon icon-close js-closeModal" onClick={hideModal}></i>
        <div className="modal__wrapper">
          <h3 className="mb-2">Vous êtes inscrit à la newsletter</h3>
          <div className="modal__body modal__body--medium narrow-2 mb-3">
            <div className="circle-icon circle-icon--check"></div>
            <p>Merci !</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsletterSubscriptionSuccessModalContent;
