// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import SettingsApi from 'api/settings/SettingsApi';
import { FETCH_SETTINGS_START, settingsFetched, settingsFetchError } from 'actions/appActions';

export default function(apiExecutor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  const settingsApi = new SettingsApi(apiExecutor, staticApiExecutor);

  return function* settingsSaga(): GeneratorType {
    yield takeLatest(FETCH_SETTINGS_START, fetchSettings);
  };

  function* fetchSettings(action): Saga<void> {
    try {
      const settings = yield call(settingsApi.fetchSettings);
      yield put(settingsFetched(settings));
    } catch (e) {
      yield put(settingsFetchError());
    }
  }
}
