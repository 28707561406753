// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';

import {
  FETCH_CF7_CONTACT_FORM_START,
  cf7FormFetched,
  cf7FormFetchedError,
  FETCH_ALL_CF7_CONTACT_FORM_START,
  cf7FormListFetched,
  cf7FormListFetchedError,
  FETCH_SINGLE_CF7_CONTACT_FORM_START,
  singlecf7FormFetched,
  singlecf7FormFetchedError,
  SEND_CF7_CONTACT_FORM_START,
  cf7FormPosted,
  cf7FormPostError,
  type FETCH_CONTACT_FORM_7_START_ACTION,
  type FETCH_SINGLE_CONTACT_FORM_7_START_ACTION,
  type FETCH_ALL_CF7_CONTACT_FORM_START_ACTION,
  type SEND_CONTACT_FORM_7_START_ACTION
} from 'actions/contactForm7Actions';
import { displayModal } from 'actions/modalActions';
import ContactForm7Api from 'api/contactForm7/ContactForm7Api';
import type { ApiExecutorType } from 'types/ApiExecutorType';

export default function(apiExecutor: ApiExecutorType) {
  const contactForm7Api = new ContactForm7Api(apiExecutor);

  return function* formSaga(): GeneratorType {
    yield takeLatest(FETCH_CF7_CONTACT_FORM_START, fetchForm);
    yield takeLatest(FETCH_SINGLE_CF7_CONTACT_FORM_START, fetchSingleForm);
    yield takeLatest(FETCH_ALL_CF7_CONTACT_FORM_START, fetchListForm);
    yield takeLatest(SEND_CF7_CONTACT_FORM_START, sendForm);
  };

  function* fetchForm(action: FETCH_CONTACT_FORM_7_START_ACTION): Saga<void> {
    try {
      const resp = yield call(contactForm7Api.fetchFirstFormContact);
      yield put(cf7FormFetched(resp));
    } catch (e) {
      yield put(cf7FormFetchedError());
    }
  }

  function* fetchSingleForm(action: FETCH_SINGLE_CONTACT_FORM_7_START_ACTION): Saga<void> {
    try {
      const { id } = action.payload;
      const resp = yield call(contactForm7Api.fetchSingleCF7FormContact, id);

      yield put(singlecf7FormFetched(resp));
    } catch (e) {
      yield put(singlecf7FormFetchedError());
    }
  }

  function* fetchListForm(action: FETCH_ALL_CF7_CONTACT_FORM_START_ACTION): Saga<void> {
    try {
      const resp = yield call(contactForm7Api.fetchAllFormContact);
      const formList = resp.sort((formA, formB) => formA.id < formB.id ? -1 : formA.id > formB.id ? 1 : 0);
      yield put(cf7FormListFetched(formList));
    } catch (e) {
      yield put(cf7FormListFetchedError());
    }
  }

  function* sendForm(action: SEND_CONTACT_FORM_7_START_ACTION): Saga<void> {
    try {
      const { formInputs, id } = action.payload;

      yield call(contactForm7Api.postFormContact, formInputs, id);
      yield put(displayModal('CONTACT_FORM_SUCCESS', formInputs, true));
      yield put(cf7FormPosted());
    } catch (e) {
      const data = {};
      if (e.invalidFields && e.invalidFields.length > 0 && e.invalidFields[0].message === 'Doublon adresse email') {
        data.title = `Désolé !`;
        data.text = `Vous ne pouvez participer qu'une seule fois.`;
      } else if (
        e.invalidFields &&
        e.invalidFields.length > 0 &&
        e.invalidFields[0].message === 'Doublon adresse email et video'
      ) {
        data.title = `Désolé !`;
        data.text = `Vous ne pouvez envoyer votre video qu'une seule fois.`;
      }
      yield put(displayModal('CONTACT_FORM_FAILED', data, true));
      yield put(cf7FormPostError());
    }
  }
}
