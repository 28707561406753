// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type CardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  description: string,
  featured_media: {
    src: string
  },
  texte_cta: string
};

export type PageListType = SEOMetaType & {
  title: string,
  excerpt: string,
  description: BlocContent[],
  sidebar_content: BlocContent[],
  slug: string,
  typeFlow: string,
  link: string,
  label: {
    id: number,
    name: string,
    slug: string
  },
  featured_media: {
    src: string,
    legend: string
  },
  liste_enfants: CardType[],
  parent: number,
  niveau: number,
  template: string,
  albums_saisons: {
    term_id: number,
    name: string,
    slug: string
  }[],
  albums_filtres: {
    term_id: number,
    name: string,
    slug: string
  }[]
};

export type RawPageListType = SEOMetaType & {
  title: {
    raw: string
  },
  excerpt: {
    raw: string
  },
  sidebar_content: {
    data: BlocContent[]
  },
  content: {
    data: BlocContent[]
  },
  slug: string,
  link: string,
  label: {
    id: number,
    name: string,
    slug: string
  },
  featured_media: {
    src: string,
    legend: string
  },
  liste_enfants: CardType[],
  parent: number,
  niveau: number,
  template: string,
  albums_saisons: {
    term_id: number,
    name: string,
    slug: string
  }[],
  albums_filtres: {
    term_id: number,
    name: string,
    slug: string
  }[]
};

export const nullPageList: PageListType = {
  ...nullSeoMeta,
  title: '',
  excerpt: '',
  description: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Chargement du contenu de la page...'
        }
      ]
    }
  ],
  sidebar_content: [],
  slug: '',
  link: '',
  typeFlow: 'PageListType',
  label: {
    id: 0,
    name: '',
    slug: ''
  },
  featured_media: {
    src: '',
    legend: ''
  },
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: ''
    }
  ],
  parent: 1,
  niveau: 0,
  template: '',
  albums_saisons: [],
  albums_filtres: []
};
