// @flow

import { STATUS_LOADING, STATUS_DEFAULT, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START,
  FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_SUCCESS,
  FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_FAILURE,
  FETCH_MATCHRESULT_START,
  FETCH_MATCHRESULT_SUCCESS,
  FETCH_MATCHRESULT_FAILURE
} from 'actions/matchResultActions';
import { nullMatchResult } from 'types/MatchResult';

import type { MatchResultType, MatchForMatchOfTheWeekType } from 'types/MatchResult';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  allMatchesForMatchOfTheWeek: MatchForMatchOfTheWeekType[],
  fetchAllMatchesResultsStatus: Status,
  result: MatchResultType,
  status: Status
};

const initialState: State = {
  allMatchesForMatchOfTheWeek: [],
  fetchAllMatchesResultsStatus: STATUS_DEFAULT,
  result: nullMatchResult,
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START:
      return {
        ...state,
        fetchAllMatchesResultsStatus: STATUS_LOADING
      };
    case FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_SUCCESS:
      return {
        ...state,
        allMatchesForMatchOfTheWeek: action.payload.results,
        fetchAllMatchesResultsStatus: STATUS_SUCCESS
      };
    case FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_FAILURE:
      return {
        ...state,
        fetchAllMatchesResultsStatus: STATUS_FAILURE
      };
    case FETCH_MATCHRESULT_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_MATCHRESULT_SUCCESS:
      return {
        ...state,
        result: action.payload.result,
        status: STATUS_SUCCESS
      };
    case FETCH_MATCHRESULT_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
