// @flow

/**
 * Only for debug env
 * Use idf as default api source
 * instead of host name
 */
function getBaseUrl(): string {
  let source = 'liguetest.pp.ffr.nextmap.io';
  // source = 'liguegrandest.pp.ffr.nextmap.io';
  // source = 'ligueaura.ffr.fr';
  // source = 'liguebretagne.ffr.fr';
  // source = 'liguecorse.ffr.fr';
  // source = 'liguehautsdefrance.ffr.fr';
  // source = 'liguenormandie.ffr.fr';
  // source = 'ligueoccitanie.ffr.fr';
  // source = 'liguesudpaca.ffr.fr';
  // source = 'liguebgfc.ffr.fr';
  // source = 'liguecentrevaldeloire.ffr.fr';
  // source = 'liguegrandest.ffr.fr';
  // source = 'ligueidf.ffr.fr';
  // source = 'liguenouvelleaquitaine.ffr.fr';
  // source = 'liguepaysdeloire.ffr.fr';
  // source = 'liguetest.local';

  if (process.env.NODE_ENV === 'production') {
    source = window.location.hostname;
  }
  if (process.env.SOURCE) {
    source = process.env.SOURCE;
  }

  return `https://api.${source}`;
}

export const TIMEOUT = 40000;
export const BASE_URL = getBaseUrl() + '/wp-json';
export const STATIC_BASE_URL = getBaseUrl() + '/static';
export const OVALE_BASE_URL = 'https://oval-e-pp.ffr.fr/ws';
export const OVALE_USERNAME = 'WebService';
export const OVALE_PASSWORD = 'webnet';

export const FORM_CONTACT_USER = 'api-ffr-ligue';
export const FORM_CONTACT_PASSWORD = '$qP)#bvYyoNltbpUFvR34)^F';
export const FORM_CONTACT_TOKEN = 'YXBpLWZmci1saWd1ZTokcVApI2J2WXlvTmx0YnBVRnZSMzQpXkY=';

const { hostname } = window.location;
const isPP = hostname.includes('nextmap') || window.location.hostname.includes('localhost');
//export const AGREGATOR_URL = isPP ? 'https://staging.agregateur.ffr.nextmap.io/graphql' : 'https://api-agregateur.ffr.fr/graphql';
export const AGREGATOR_URL = 'https://api-agregateur.ffr.fr/graphql';
export const MEILISEARCH_URL = isPP
  ? 'https://commons-docker-meilisearch-staging.ffr.nextmap.cloud/'
  : 'https://commons-docker-meilisearch.ffr.nextmap.cloud/';
export const MEILISEARCH_KEY = isPP
  ? '1944cc48b8eaf238a4798e97e4856c2a3608c8833b3f7e11ea4778289308ef96'
  : '28867d0ba127ab4c4a629a682309c8b8eed9fb3514d9cf47d014fd9caefab4b0';
