// @flow

import type { EventType } from 'types/Event';

export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export type FETCH_EVENTS_START_ACTION = {
  type: 'FETCH_EVENTS_START',
  payload: {
    from: Date,
    to: Date
  }
};

export type FETCH_EVENTS_FAILURE_ACTION = {
  type: 'FETCH_EVENTS_FAILURE'
};

export type FETCH_EVENTS_SUCCESS_ACTION = {
  type: 'FETCH_EVENTS_SUCCESS',
  payload: {
    events: Array<EventType>
  }
};

export type Action = FETCH_EVENTS_START_ACTION | FETCH_EVENTS_FAILURE_ACTION | FETCH_EVENTS_SUCCESS_ACTION;

export function fetchEvents(from: Date, to: Date): FETCH_EVENTS_START_ACTION {
  return {
    type: FETCH_EVENTS_START,
    payload: {
      from,
      to
    }
  };
}

export function eventsFetched(events: Array<EventType>): FETCH_EVENTS_SUCCESS_ACTION {
  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: { events }
  };
}

export function eventsFetchError(): FETCH_EVENTS_FAILURE_ACTION {
  return {
    type: FETCH_EVENTS_FAILURE
  };
}
