// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertFacebookPostsFromApi } from 'services/Social';


function FacebookApi(executor: ApiExecutorType) {
  return { fetchPosts };

  function fetchPosts() {
    return executor.get(`ffr/v1/rs_facebook`)
            .then(responses => {
              if (responses.data) {
                return responses.data.map(resp => convertFacebookPostsFromApi(resp));
              }
              return [];
            });
  }
}

export default FacebookApi;
