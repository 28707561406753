// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import { FETCH_EVENTS_START, FETCH_EVENTS_SUCCESS, FETCH_EVENTS_FAILURE } from 'actions/eventsActions';

import type { EventType } from 'types/Event';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  events: Array<EventType>,
  status: Status
};

const initialState: State = {
  events: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_EVENTS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.events,
        status: STATUS_SUCCESS
      };
    case FETCH_EVENTS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
