// @flow

import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { getPlaceholder } from 'utils/assetsUtils';
import { Link } from 'react-router-dom';
import BackgroundImage from 'components/fragments/BackgroundImage';
import { handleTagEvent } from 'utils/tagManagerUtils';

type Props = {
  url: string,
  backgroundUrl?: Object,
  title: string,
  linkLabel: string,
  desc?: string,
  isCompact?: boolean,
  onClick?: () => void
};

class CardThumbnail extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    url: '#',
    isCompact: false,
    backgroundUrl: getPlaceholder(),
    title: 'Bulletins officiels',
    linkLabel: 'En savoir plus'
  };

  onLinkClick = () => {
    const { onClick, title } = this.props;

    handleTagEvent('module contenu', title, window.location.pathname);

    if (onClick) {
      onClick();
    }
  };

  render() {
    const { url, isCompact, title, desc, linkLabel, backgroundUrl } = this.props;
    const classNamesCard = dynamicClassName('box card card--content');
    isCompact && classNamesCard.add('card--compact');

    return (
      <Link to={url} className={classNamesCard.build()} onClick={this.onLinkClick}>
        <BackgroundImage
          className="card__thumbnail img-blue"
          illustration={backgroundUrl ? backgroundUrl.src : getPlaceholder()}
          srcset={backgroundUrl && backgroundUrl.sizes}
        />
        <div className="card__body">
          <h3 className="card__title">{title}</h3>
          {!isCompact && desc && <p className="card__desc">{desc}</p>}
        </div>
        <div className="card__footer">
          <span className="link-chevron">
            {linkLabel}
            <i className="icon icon-chevron-right" />
          </span>
        </div>
      </Link>
    );
  }
}

export default CardThumbnail;
