// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import ArticleApi from 'api/article/ArticleApi';
import { FETCH_ARTICLE_START, articleFetched, articleFetchError } from 'actions/articleActions';
import type { FETCH_ARTICLE_START_ACTION } from 'actions/articleActions';

import { FETCH_CATEGORIES_START, categoriesFetched, categoriesFetchError } from 'actions/categoriesActions';
import type { FETCH_CATEGORIES_START_ACTION } from 'actions/categoriesActions';

export default function(apiExecutor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  const articleApi = new ArticleApi(apiExecutor, staticApiExecutor);

  return function* articleSaga(): GeneratorType {
    yield takeLatest(FETCH_ARTICLE_START, fetchArticle);
    yield takeLatest(FETCH_CATEGORIES_START, fetchArticleCategories);
  };

  function* fetchArticle(action: FETCH_ARTICLE_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const article = yield call(articleApi.fetchArticleBySlug, slug);
      if (!article.slug) {
        throw new Error('No article with slug ' + slug);
      }

      yield put(articleFetched(article, true));
    } catch (e) {
      yield put(articleFetchError(e));
    }
  }

  function* fetchArticleCategories(action: FETCH_CATEGORIES_START_ACTION): Saga<void> {
    try {
      const categories = yield call(articleApi.fetchCategories);
      yield put(categoriesFetched(categories));
    } catch (e) {
      yield put(categoriesFetchError(e));
    }
  }
}
