// @flow
import type { RawCompetitionLayoutType, CompetitionLayoutType } from 'types/CompetitionLayout';
import { TYPE_TERRITORIAL } from 'constants/competitionsConstants';
import { mergeIntoDefault } from 'utils/objectUtils';
import { nullCompetitionFromBo, nullCompetitionElement } from 'types/Competition';
import type { CompetitionFromBoType, CompetitionListElementType, RawCompetitionFromBoType } from 'types/Competition';

const nullCompetitionLayout: CompetitionLayoutType = {
  type: TYPE_TERRITORIAL,
  division: '',
  date: new Date(),
  id: '',
  slug: '',
  nouveaux_resultats: 0
};

export function convertCompetitionLayoutFromApi(rawCompetitionLayout: RawCompetitionLayoutType): CompetitionLayoutType {
  if (!rawCompetitionLayout) {
    return nullCompetitionLayout;
  }
  const {
    id,
    famille,
    date,
    title: { raw },
    slug,
    nouveaux_resultats
  } = rawCompetitionLayout;

  return mergeIntoDefault(nullCompetitionLayout, {
    type: famille,
    division: raw,
    slug,
    id,
    date,
    nouveaux_resultats
  });
}

export function convertCompetitionElementFromApi(rawCompetition: RawCompetitionLayoutType): CompetitionListElementType {
  if (!rawCompetition) {
    return nullCompetitionElement;
  }
  const {
    slug,
    famille,
    classe_age_code,
    sexe,
    menu_order,
    title: { raw },
    nouveaux_resultats
  } = rawCompetition;

  return mergeIntoDefault(nullCompetitionElement, {
    division: raw,
    slug,
    recents: nouveaux_resultats,
    age: classe_age_code,
    sexe,
    order: menu_order === -1 ? 10000 : menu_order,
    famille
  });
}

export function convertCompetitionFromBoApi(rawCompetition: Array<RawCompetitionFromBoType>): CompetitionFromBoType {
  if (!rawCompetition) {
    return nullCompetitionFromBo;
  }
  if (rawCompetition.length === 0) {
    return nullCompetitionFromBo;
  }

  let mediasMerged;
  let documentsMerged;

  const {
    slug,
    liste_enfants,
    title: { raw },
    sidebar_content,
    meta_title,
    meta_description
  } = rawCompetition[0];

  if (liste_enfants) {
    if (liste_enfants.documents) {
      documentsMerged = mergeIntoDefault(
        {
          documents: nullCompetitionFromBo.documents
        },
        {
          documents: liste_enfants.documents.content.data
        }
      );
    } else {
      documentsMerged = nullCompetitionFromBo.documents;
    }

    if (liste_enfants.medias) {
      mediasMerged = mergeIntoDefault(
        {
          content: nullCompetitionFromBo.medias.content,
          media: nullCompetitionFromBo.medias.media
        },
        {
          content: liste_enfants.medias.content.data,
          media: liste_enfants.medias.albums
        }
      );
    } else {
      mediasMerged = nullCompetitionFromBo.medias;
    }
  }

  return mergeIntoDefault(nullCompetitionFromBo, {
    meta_title,
    meta_description,
    documents: documentsMerged,
    medias: mediasMerged,
    division: raw,
    sidebar: sidebar_content.data,
    slug
  });
}
