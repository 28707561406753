// @flow
import type { RawJobDetailType, JobDetailType } from 'types/JobDetail';
import type { JobFilterType, RawJobFilterType } from 'types/JobFilter';
import type { JobListType } from 'types/JobList';
import { nullJobFilter } from 'types/JobFilter';
import { nullJobDetail } from 'types/JobDetail';
import { nullJobList } from 'types/JobList';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertJobListFromApi(rawJobList: RawJobDetailType): JobListType {
  if (!rawJobList) {
    return nullJobList;
  }

  const {
    title,
    content,
    slug,
    link,
    meta_title,
    meta: {
      emailContact
    },
    meta_description,
    date_cloture,
    type_contrat,
    region,
    ville,
    domaine_emploi,
    structure,
  } = rawJobList;

  return mergeIntoDefault(nullJobList, {
    title: title.raw,
    description: content.data,
    slug,
    link,
    meta_title,
    meta_description,
    email:emailContact,
    date_cloture,
    type_contrat,
    region,
    city: ville,
    jobtype: domaine_emploi,
    structure
  });
}

export function convertJobListFilterFromApi(rawJobFilter: RawJobFilterType): JobFilterType {
  if (!rawJobFilter) {
    return nullJobFilter;
  }

  const {
    id,
    slug,
    name,
    link,
  } = rawJobFilter;

  return mergeIntoDefault(nullJobFilter, {
    id,
    slug,
    name,
    link,
  });
}

export function convertJobDetailFromApi(rawJobDetails: Array<RawJobDetailType>): JobDetailType {
  if (!rawJobDetails) {
    return nullJobDetail;
  }

  if (rawJobDetails[0]) {
    const {
      id,
      title,
      content,
      slug,
      meta: {
        emailContact
      },
      meta_title,
      meta_description,
      structure,
      ville,
      date_cloture,
      domaine_emploi,
      link,
      type_contrat,
      featured_pdf,
    } = rawJobDetails[0];

    return mergeIntoDefault(nullJobDetail, {
      id,
      content: content.data,
      title: title ? title.raw : '',
      slug,
      meta_title,
      meta_description,
      structure,
      city:ville,
      date_cloture,
      email: emailContact,
      jobtype: domaine_emploi,
      link,
      type_contrat,
      featured_pdf,
    });
  }
  return nullJobDetail;
}

export function convertJobDetailPreviewFromApi(rawJobDetails: RawJobDetailType): JobDetailType {
  if (!rawJobDetails) {
    return nullJobDetail;
  }

  const {
    title,
    content,
    slug,
    link,
    sidebar_content,
    meta_title,
    meta_description,
  } = rawJobDetails;
  let membersGroups;

  return mergeIntoDefault(nullJobDetail, {
    content: content.data,
    title: title ? title.raw : '',
    slug,
    link,
    membersGroups,
    sidebar_content: sidebar_content.data,
    meta_title,
    meta_description,
  });
}
