// @flow
import React, { PureComponent } from 'react';
import type { NewsletterSubscriptionType, NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';
import type { Status } from 'types/Status';
import { STATUS_DEFAULT } from 'constants/statusConstants';
import SelectInput from 'components/fragments/input/SelectInput';
import TextInput from 'components/fragments/input/TextInput';
import CheckboxInput from 'components/fragments/input/CheckboxInput';
import type { DisplayModalFunctionType } from 'types/Modal';
import { validateEmail } from 'utils/validatorUtils';

export type StateProps = {
  options: NewsletterSubscriptionType,
  status: Status,
  newsletterSetting: {
    type: false | 'campaign_monitor' | 'lien' | 'mailchimp',
    lien?: string,
    api_key?: string
  },
  structureName: string
};

export type DispatchProps = {
  fetchOptions: (campaignMonitorKey: string) => void,
  subscribeNewsletter: (subscription: NewsletterSubscriptionEntryType, campaignMonitorKey: string) => void,
  displayModal: DisplayModalFunctionType
};

type Props = {
  mode?: string
} & StateProps &
  DispatchProps;

type State = {
  profil: null | string,
  email: null | string,
  emailValide: boolean,
  consentOpt1: boolean,
  consentOpt2: boolean,
  consentToTrack: boolean
};

class NewsletterSubscriber extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    profil: null,
    email: null,
    emailValide: false,
    consentOpt1: false,
    consentOpt2: false,
    consentToTrack: false
  };

  componentDidMount() {
    const { newsletterSetting, fetchOptions } = this.props;

    if (
      newsletterSetting &&
      newsletterSetting.type &&
      newsletterSetting.type === 'campaign_monitor' &&
      newsletterSetting.api_key
    ) {
      fetchOptions(newsletterSetting.api_key || '');
    }
  }

  componentDidUpdate() {
    const { newsletterSetting, status, fetchOptions } = this.props;

    if (newsletterSetting.type === 'campaign_monitor' && status === STATUS_DEFAULT) {
      fetchOptions(newsletterSetting.api_key || '');
    }
  }

  setProfil = (profil: string) => {
    this.setState({ profil });
  };

  setEmail = (email: string) => {
    if (validateEmail(email)) {
      this.setState({
        email,
        emailValide: true
      });
    } else {
      this.setState({ emailValide: false });
    }
  };

  setConsent = (value: boolean, checkboxType: string) => {
    this.setState({ [checkboxType]: value });
  };

  handleSubmitClicked = (e: MouseEvent) => {
    e.preventDefault();
    const { email, consentOpt1, consentOpt2 } = this.state;
    const { subscribeNewsletter, displayModal, structureName } = this.props;
    const { api_key } = this.props.newsletterSetting;
    const campaignMonitorKey = !api_key ? '' : api_key;

    if (!consentOpt1) {
      displayModal('NEWSLETTER_MISSING_OPT', { structureName }, true);
    } else if (!!email && validateEmail(email)) {
      const options = {
        ConsentToTrack: consentOpt1 ? 'yes' : 'no',
        CustomFields: [
          {
            Key: 'Optin1',
            Value: consentOpt1 ? 'yes' : 'no'
          },
          {
            Key: 'Optin2',
            Value: consentOpt2 ? 'yes' : 'no'
          }
        ],
        EmailAddress: email,
        Name: '',
        action: 'subscribe'
      };
      subscribeNewsletter(options, campaignMonitorKey);
    }
  };

  renderLinkButton = () => {
    const { structureName } = this.props;
    return (
      <div className="col col-lg-9">
        <h5 className="mb-1">Newsletter</h5>
        <p className="mb-2">Recevez par email les nouveautés de la ligue {structureName} en fonction de votre profil</p>
        <a
          title={`S'inscrire à la newsletter de la ligue ${structureName}`}
          className="btn btn--primary"
          href={this.props.newsletterSetting.lien || '#'}
        >
          {"S'inscrire"}
        </a>
      </div>
    );
  };

  render() {
    const {
      mode,
      newsletterSetting: { type },
      options: { options },
      status,
      structureName
    } = this.props;
    const { emailValide, email } = this.state;

    const labels = {
      consent: `J'accepte les emails de la ligue ${structureName} de rugby à propos des nouveautés et promotions de la Ligue.
      Je peux me désinscrire à tout moment en utilisant le lien pour me désabonner au pied de la newsletter.`,
      optin1: `J’autorise la FFR à utiliser mes données personnelles (e-mail, adresse postale) à des fins
      promotionnelles ou commerciales pour ses propres activités (billetterie, produits dérivés).`,
      optin2: `J’autorise la FFR à transmettre certaines de mes données personnelles (nom, prénom, e-mail, adresse postale)
      à des tiers, et notamment ses partenaires et fournisseurs officiels, à des fins commerciales, associatives ou humanitaires.`
    };

    // si pas de type de newsletter ou qu'on a pas fini de fetch les options pour récupérer les clés d'api alors on ne rend rien
    if (type === false || (type === 'campaign_monitor' && status === STATUS_DEFAULT)) return null;

    // si on a un type de newsletter lien => on retourne le bouton
    if (type === 'lien') return this.renderLinkButton();
    // si on utilise Mailchimp ou Campagn Monitor
    else {
      // si on n'a pas d'option => on retourne rien
      if (type === 'campaign_monitor' && options.length === 0) {
        return null;
      }

      if (mode === 'sidebar') {
        return (
          <div className="card__body">
            <h3 className="card__title">Newsletter</h3>
            <p className="card__desc">Recevez par email les nouveautés de la ligue {structureName}</p>
            <form action="#">
              <TextInput
                id="email"
                isDark
                placeholder="Adresse mail*"
                hasError={email !== null && !validateEmail(email)}
                onChange={this.setEmail}
              />
              <div>
                <CheckboxInput
                  isDark
                  id="consent"
                  label={labels['consent']}
                  onChange={value => this.setConsent(value, 'consent')}
                />
                <CheckboxInput
                  isDark
                  id="consentOpt1"
                  label={labels['optin1']}
                  onChange={value => this.setConsent(value, 'consentOpt1')}
                />
                <CheckboxInput
                  isDark
                  id="consentOpt2"
                  label={labels['optin2']}
                  onChange={value => this.setConsent(value, 'consentOpt2')}
                />
              </div>
              <div></div>
              <button
                className="btn btn--primary btn--full"
                type="submit"
                onClick={this.handleSubmitClicked}
                disabled={!emailValide && true}
              >
                <i className="icon icon-valid is-inline" />
                Inscription
              </button>
            </form>
          </div>
        );
      }

      // sinon on retourne le formulaire d'inscription
      return (
        <div className="col col-lg-9">
          <h5 className="mb-1">Newsletter</h5>
          <p className="mb-2">Recevez par email les nouveautés de la {structureName} en fonction de votre profil</p>
          <form action="#">
            {!!options.length && (
              <SelectInput
                isDark
                placeholder="Je suis..."
                options={this.props.options.options.map(option => ({ label: option, value: option }))}
                onChange={this.setProfil}
                hasError={this.state.profil === ''}
              />
            )}
            <TextInput
              id="email"
              isDark
              placeholder="Adresse mail*"
              hasError={this.state.email !== null && !validateEmail(this.state.email)}
              onChange={this.setEmail}
              isFull
            />
            <div>
              <CheckboxInput
                isDark
                id="consent"
                label={labels['consent']}
                onChange={value => this.setConsent(value, 'consent')}
                isFull
              />
              <CheckboxInput
                isDark
                id="consentOpt1"
                label={labels['optin1']}
                onChange={value => this.setConsent(value, 'consentOpt1')}
                isFull
              />
              <CheckboxInput
                isDark
                id="consentOpt2"
                label={labels['optin2']}
                onChange={value => this.setConsent(value, 'consentOpt2')}
                isFull
              />
            </div>
            <button
              className="btn btn--primary"
              type="submit"
              onClick={this.handleSubmitClicked}
              disabled={!emailValide && true}
            >
              <i className="icon icon-valid is-inline" />
              Inscription
            </button>
          </form>
        </div>
      );
    }
  }
}

export default NewsletterSubscriber;
