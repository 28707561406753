// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertYoutubePostsFromApi } from 'services/Social';

function YoutubeApi(executor: ApiExecutorType) {
  return { fetchPosts };

  function fetchPosts() {
    return executor.get(`ffr/v1/rs_youtube`)
            .then(responses => {
              const filteredResp = responses.filter(response => !response.retweeted_status);
              return filteredResp.map(resp => convertYoutubePostsFromApi(resp));
            });
  }
}

export default YoutubeApi;
