// @flow
import React, { PureComponent, Fragment } from 'react';
import type { ComponentType } from 'react';
import { Helmet as RawHelmet } from 'react-helmet';

const MAX_DESCRIPTION_LENGTH = 300;

const Helmet = ({ children, ...rest }: *) => (
  <RawHelmet {...rest}>
    {children}
  </RawHelmet>
);

function withHelmet(WrapperComponent: ComponentType<*>) {
  return (helmetChildren: Function = (props: *) => null) => {
    return class HelmetWrapper extends PureComponent<*> {
      render() {
        return (
          <Fragment>
            {helmetChildren(Helmet, this.props)}
            <WrapperComponent {...this.props} />
          </Fragment>
        );
      }
    };
  };
}

export { withHelmet, MAX_DESCRIPTION_LENGTH };
