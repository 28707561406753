// @flow

import { takeLatest, put, call, select, take, all } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import CompetitionsApi from 'api/competition/CompetitionApi';
import { fetchSettings, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILURE } from 'actions/appActions';
import {
  FETCH_COMPETITIONS_START,
  competitionsFetched,
  competitionsFetchError,
  FETCH_COMPETITIONS_ELEMENTS_START,
  competitionsElementsFetched,
  competitionsElementsFetchError
} from 'actions/competitionsActions';

export default function(apiExecutor: ApiExecutorType) {
  const competitionsApi = new CompetitionsApi(apiExecutor);

  return function* competitionsSaga(): GeneratorType {
    yield takeLatest(FETCH_COMPETITIONS_START, fetchCompetitions);
    yield takeLatest(FETCH_COMPETITIONS_ELEMENTS_START, fetchCompetitionsElements);
  };

  function* fetchCompetitions(action): Saga<void> {
    try {
      let state = yield select();
      let ligue = state.settingsState.ligue_id;
      if (!ligue) {
        yield all([take(FETCH_SETTINGS_SUCCESS), take(FETCH_SETTINGS_FAILURE)]);

        state = yield select();
        ligue = state.settingsState.ligue_id;
      }

      const competitions = yield call(competitionsApi.fetchCompetitions, ligue);

      yield put(competitionsFetched(competitions));
    } catch (e) {
      yield put(competitionsFetchError());
    }
  }

  function* fetchCompetitionsElements(action): Saga<void> {
    try {
      let state = yield select();
      let ligue = state.settingsState.ligue_id;

      if (!ligue) {
        fetchSettings();
        yield take(FETCH_SETTINGS_SUCCESS);

        state = yield select();
        ligue = state.settingsState.ligue_id;
      }

      const competitions = yield call(competitionsApi.fetchCompetitionsElements, ligue);

      yield put(competitionsElementsFetched(competitions));
    } catch (e) {
      yield put(competitionsElementsFetchError());
    }
  }
}
