// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import { FETCH_CLUB_START, FETCH_CLUB_SUCCESS, FETCH_CLUB_FAILURE } from 'actions/clubActions';
import { nullClub } from 'types/Club';

import type { ClubType } from 'types/Club';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  club: ClubType,
  status: Status
};

const initialState: State = {
  club: nullClub,
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_CLUB_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_CLUB_SUCCESS:
      return {
        ...state,
        club: action.payload.club,
        status: STATUS_SUCCESS
      };
    case FETCH_CLUB_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
