// @flow

import { setWindowApp, getWindowApp } from 'utils/windowUtils';

export function getPublicAssets(link: string) {
  return `${process.env.PUBLIC_URL || ''}${link}`;
}

export function getPlaceholder(): string {
  const host = window.location.hostname.split('.')[0] || undefined;
  const filename = ligue => `placeholder-${ligue}.jpg`;
  const path = filename => `/img/placeholder/${filename}`;
  const defaultSource = getPublicAssets(path(filename('ffr')));
  const targetSource = getPublicAssets(path(filename(host || '')));
  if (
    [
      'ligueaura', 
      'liguebgfc', 
      'liguebretagne', 
      'liguecentrevaldeloire', 
      'liguecorse', 
      'liguegrandest', 
      'liguehautsdefrance', 
      'ligueidf', 
      'liguenormandie', 
      'liguenouvelleaquitaine',
      'ligueoccitanie',
      'liguepaysdeloire',
      'liguesudpaca'
    ].indexOf(host) > -1
  ){
    setWindowApp('placeholder', targetSource);
    return getWindowApp('placeholder') || targetSource;
  } else {
    setWindowApp('placeholder', defaultSource);
  }
  return getWindowApp('placeholder') || defaultSource;
}
