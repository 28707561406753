// @flow

import {
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS,
  STATUS_DEFAULT,
  STATUS_UNKNOWN
} from 'constants/statusConstants';
import {
  FETCH_PAGE_DETAIL_START,
  FETCH_PAGE_DETAIL_SUCCESS,
  FETCH_PAGE_DETAIL_FAILURE,
  FETCH_UNKNOWN_PAGE_DETAIL
} from 'actions/pageActions';

import type { DocumentType, MemberType, AnnuaireType, DepartmentType } from 'types/PageDetail';
import type { ActionType } from 'types/Actions';
import type { Status } from 'types/Status';
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type State = SEOMetaType & {
  title: string,
  content: Array<BlocContent>,
  slug: string,
  featured_media: {
    src: string,
    legend: string
  },
  status: Status,
  documents: Array<DocumentType>,
  annuaires: Array<AnnuaireType>,
  membersGroups: Array<{
    name: string,
    child: Array<MemberType>
  }>,
  departmentsGroups: Array<DepartmentType>,
  sidebar_content: Array<BlocContent>,
  template: string
};

const initialState: State = {
  ...nullSeoMeta,
  title: '',
  content: [],
  slug: '',
  featured_media: {
    src: '',
    legend: ''
  },
  status: STATUS_DEFAULT,
  annuaires: [],
  documents: [],
  membersGroups: [],
  departmentsGroups: [],
  sidebar_content: [],
  template: ''
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PAGE_DETAIL_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_PAGE_DETAIL_SUCCESS:
      return {
        ...state,
        title: action.payload.pageDetail.title,
        content: action.payload.pageDetail.content,
        slug: action.payload.pageDetail.slug,
        featured_media: action.payload.pageDetail.featured_media,
        annuaires: action.payload.pageDetail.annuaires,
        documents: action.payload.pageDetail.documents,
        membersGroups: action.payload.pageDetail.membersGroups,
        departmentsGroups: action.payload.pageDetail.departmentsGroups,
        sidebar_content: action.payload.pageDetail.sidebar_content,
        meta_description: action.payload.pageDetail.meta_description,
        meta_title: action.payload.pageDetail.meta_title,
        template: action.payload.pageDetail.template,
        status: STATUS_SUCCESS
      };
    case FETCH_PAGE_DETAIL_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_UNKNOWN_PAGE_DETAIL:
      return {
        ...state,
        status: STATUS_UNKNOWN
      };
    default:
      return state;
  }
}
