// @flow

import type { CompetitionLayoutType } from 'types/CompetitionLayout';
import type { CompetitionListElementType } from 'types/Competition';

export const FETCH_COMPETITIONS_START = 'FETCH_COMPETITIONS_START';
export const FETCH_COMPETITIONS_ELEMENTS_START = 'FETCH_COMPETITIONS_ELEMENTS_START';
export const FETCH_COMPETITIONS_SUCCESS = 'FETCH_COMPETITIONS_SUCCESS';
export const FETCH_COMPETITIONS_ELEMENTS_SUCCESS = 'FETCH_COMPETITIONS_ELEMENTS_SUCCESS';
export const FETCH_COMPETITIONS_FAILURE = 'FETCH_COMPETITIONS_FAILURE';
export const FETCH_COMPETITIONS_ELEMENTS_FAILURE = 'FETCH_COMPETITIONS_ELEMENTS_FAILURE';

export type FETCH_COMPETITIONS_START_ACTION = {
  type: 'FETCH_COMPETITIONS_START'
};

export type FETCH_COMPETITIONS_ELEMENTS_START_ACTION = {
  type: 'FETCH_COMPETITIONS_ELEMENTS_START'
};

export type FETCH_COMPETITIONS_FAILURE_ACTION = {
  type: 'FETCH_COMPETITIONS_FAILURE'
};

export type FETCH_COMPETITIONS_ELEMENTS_FAILURE_ACTION = {
  type: 'FETCH_COMPETITIONS_ELEMENTS_FAILURE'
};

export type FETCH_COMPETITIONS_SUCCESS_ACTION = {
  type: 'FETCH_COMPETITIONS_SUCCESS',
  payload: {
    competitions: Array<CompetitionLayoutType>
  }
};

export type FETCH_COMPETITIONS_ELEMENTS_SUCCESS_ACTION = {
  type: 'FETCH_COMPETITIONS_ELEMENTS_SUCCESS',
  payload: {
    competitionsElements: Array<CompetitionListElementType>
  }
};

export type Action =
  | FETCH_COMPETITIONS_START_ACTION
  | FETCH_COMPETITIONS_ELEMENTS_START_ACTION
  | FETCH_COMPETITIONS_FAILURE_ACTION
  | FETCH_COMPETITIONS_ELEMENTS_FAILURE_ACTION
  | FETCH_COMPETITIONS_SUCCESS_ACTION
  | FETCH_COMPETITIONS_ELEMENTS_SUCCESS_ACTION;

export function fetchCompetitions(ligue: number): FETCH_COMPETITIONS_START_ACTION {
  return {
    type: FETCH_COMPETITIONS_START,
    payload: { ligue }
  };
}

export function fetchCompetitionsElements(ligue: number): FETCH_COMPETITIONS_ELEMENTS_START_ACTION {
  return {
    type: FETCH_COMPETITIONS_ELEMENTS_START,
    payload: { ligue }
  };
}

export function competitionsFetched(competitions: Array<CompetitionLayoutType>): FETCH_COMPETITIONS_SUCCESS_ACTION {
  return {
    type: FETCH_COMPETITIONS_SUCCESS,
    payload: { competitions }
  };
}

export function competitionsElementsFetched(
  competitionsElements: Array<CompetitionListElementType>
): FETCH_COMPETITIONS_ELEMENTS_SUCCESS_ACTION {
  return {
    type: FETCH_COMPETITIONS_ELEMENTS_SUCCESS,
    payload: { competitionsElements }
  };
}

export function competitionsFetchError(): FETCH_COMPETITIONS_FAILURE_ACTION {
  return {
    type: FETCH_COMPETITIONS_FAILURE
  };
}

export function competitionsElementsFetchError(): FETCH_COMPETITIONS_ELEMENTS_FAILURE_ACTION {
  return {
    type: FETCH_COMPETITIONS_ELEMENTS_FAILURE
  };
}
