// @flow

export function mergeIntoDefault(defaultParams: Object = {}, params: Object = {}): Object {
  return Object.keys(defaultParams).reduce((result, value) => {
    if (params[value] !== null && params[value] !== undefined && typeof params[value] === typeof defaultParams[value]) {
      result[value] = params[value];
    } else {
      result[value] = defaultParams[value];
    }

    return result;
  }, {});
}

export function promisify(fn: any): any {
  return new Promise((resolve, reject) => {
    try {
      resolve(fn);
    } catch (e) {
      reject(e);
    }
  });
}

export function convertToValidDateFormat(str: string): string {
  return str.replace(/-/g, '/');
}

export function convertFacebookDate(date: string) {
  return date.replace(/\//g, '-');
}

export function convertObjectToUrlEncoded(element: any,key: any,list: any){
  var listArray = list || [];
    if (typeof(element) === 'object') {
      for (var idx in element)
        convertObjectToUrlEncoded(element[idx],key?key+'['+idx+']':idx,listArray);
    } else {
      listArray.push(key+'='+encodeURIComponent(element));
    }
    return listArray.join('&');
  }
