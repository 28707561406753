// @flow
import React from 'react';

import { getPublicAssets } from 'utils/assetsUtils';

type Props = {
  content: any,
  hideModal: () => void
};

function NewsletterSubscriptionFailedModalContent({ content, hideModal }: Props) {
  const text = content.wording || "Nous avons rencontré un problème lors de l'inscription";

  return (
    <div className="modal__container col-md-6 col-lg-4">
      <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
        <i role="button" tabIndex={0} className="icon icon-close js-closeModal" onClick={hideModal}></i>
        <div className="modal__wrapper">
          <h3 className="mb-2">{text}</h3>
          <div className="modal__body modal__body--medium narrow-2 mb-3">
            <div className="v-spacing-1"></div>
            <img width="80" height="80" src={getPublicAssets('/img/icon/Icon80-Alert.svg')} alt="" />
            <p>Merci de vérifier les informations envoyées!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsletterSubscriptionFailedModalContent;
