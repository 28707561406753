// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { FETCH_TWITTER_START, twitterPostsFetched, twitterPostsFetchError } from 'actions/twitterActions';
import TwitterApi from 'api/social/TwitterApi';
import type { FETCH_TWITTER_START_ACTION } from 'actions/twitterActions';

export default function(apiExecutor: ApiExecutorType) {
  const twitterApi = new TwitterApi(apiExecutor);

  return function* twitterSaga(): GeneratorType {
    yield takeLatest(FETCH_TWITTER_START, fetchTwitterPosts);
  };

  function* fetchTwitterPosts(action: FETCH_TWITTER_START_ACTION): Saga<void> {
    try {
      const posts = yield call(twitterApi.fetchPosts);
      yield put(twitterPostsFetched(posts));
    } catch (e) {
      yield put(twitterPostsFetchError());
    }
  }
}
