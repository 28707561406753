// @flow
import { FETCH_ARTICLE_SUCCESS } from 'actions/articleActions';
import { FETCH_ARTICLES_SUCCESS } from 'actions/newsletterActions';
import { FETCH_ARTICLES_CATEGORY_SUCCESS } from 'actions/newsletterCategoryActions';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_PAGE_NODE_SUCCESS, FETCH_PAGE_DETAIL_SUCCESS } from 'actions/pageActions';
import { FETCH_CLUBS_SUCCESS } from 'actions/clubsActions';
import { FETCH_CLUB_SUCCESS } from 'actions/clubActions';
import { FETCH_EVENTS_SUCCESS, FETCH_EVENTS_FAILURE } from 'actions/eventsActions';
import { FETCH_EVENT_SUCCESS } from 'actions/eventActions';
import { SEND_CF7_CONTACT_FORM_START } from 'actions/contactForm7Actions';
import { FETCH_FAQ_THEME_SUCCESS } from 'actions/faqActions';
import { SUBSCRIBE_NEWSLETTER_START } from 'actions/newsletterSubscriptionActions';
import { FETCH_COMPETITIONS_ELEMENTS_SUCCESS } from 'actions/competitionsActions';
import { FETCH_COMPETITION_SUCCESS } from 'actions/competitionActions';

import type { FETCH_ARTICLE_SUCCESS_ACTION } from 'actions/articleActions';
import type { FETCH_ARTICLES_SUCCESS_ACTION } from 'actions/newsletterActions';
import type { FETCH_ARTICLES_CATEGORY_SUCCESS_ACTION } from 'actions/newsletterCategoryActions';
import type {
  FETCH_PAGE_LIST_SUCCESS_ACTION,
  FETCH_PAGE_NODE_SUCCESS_ACTION,
  FETCH_PAGE_DETAIL_SUCCESS_ACTION
} from 'actions/pageActions';
import type { FETCH_CLUBS_SUCCESS_ACTION } from 'actions/clubsActions';
import type { FETCH_CLUB_SUCCESS_ACTION } from 'actions/clubActions';
import type { FETCH_EVENTS_SUCCESS_ACTION, FETCH_EVENTS_FAILURE_ACTION } from 'actions/eventsActions';
import type { FETCH_EVENT_SUCCESS_ACTION } from 'actions/eventActions';
import type { SEND_CONTACT_FORM_7_START_ACTION } from 'actions/contactForm7Actions';
import type { FETCH_FAQ_THEME_SUCCESS_ACTION } from 'actions/faqActions';
import type { SUBSCRIBE_NEWSLETTER_START_ACTION } from 'actions/newsletterSubscriptionActions';
import type { FETCH_COMPETITIONS_ELEMENTS_SUCCESS_ACTION } from 'actions/competitionsActions';
import type { FETCH_COMPETITION_SUCCESS_ACTION } from 'actions/competitionActions';

export const hitTagsArray = [
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_CATEGORY_SUCCESS,
  FETCH_PAGE_LIST_SUCCESS,
  FETCH_PAGE_NODE_SUCCESS,
  FETCH_PAGE_DETAIL_SUCCESS,
  FETCH_CLUBS_SUCCESS,
  FETCH_CLUB_SUCCESS,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENT_SUCCESS,
  SEND_CF7_CONTACT_FORM_START,
  FETCH_FAQ_THEME_SUCCESS,
  SUBSCRIBE_NEWSLETTER_START,
  FETCH_COMPETITIONS_ELEMENTS_SUCCESS,
  FETCH_COMPETITION_SUCCESS
];

export type hitTagsArrayType =
  | FETCH_ARTICLE_SUCCESS_ACTION
  | FETCH_ARTICLES_SUCCESS_ACTION
  | FETCH_ARTICLES_CATEGORY_SUCCESS_ACTION
  | FETCH_PAGE_LIST_SUCCESS_ACTION
  | FETCH_PAGE_NODE_SUCCESS_ACTION
  | FETCH_PAGE_DETAIL_SUCCESS_ACTION
  | FETCH_CLUBS_SUCCESS_ACTION
  | FETCH_CLUB_SUCCESS_ACTION
  | FETCH_EVENTS_SUCCESS_ACTION
  | FETCH_EVENTS_FAILURE_ACTION
  | FETCH_EVENT_SUCCESS_ACTION
  | SEND_CONTACT_FORM_7_START_ACTION
  | FETCH_FAQ_THEME_SUCCESS_ACTION
  | SUBSCRIBE_NEWSLETTER_START_ACTION
  | FETCH_COMPETITIONS_ELEMENTS_SUCCESS_ACTION
  | FETCH_COMPETITION_SUCCESS_ACTION;
