export function setBodyStyle(styles) {
  if (!document.body) {
    return;
  }

  for (const i in styles) {
    document.body.style[i] = styles[i];
  }
}

export function resetBodyStyle() {
  if (!document.body) {
    return;
  }

  document.body.style = null;
}
