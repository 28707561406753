// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import { FETCH_ARTICLE_START, FETCH_ARTICLE_SUCCESS, FETCH_ARTICLE_FAILURE } from 'actions/articleActions';
import { nullArticle } from 'types/Article';

import type { ArticleType } from 'types/Article';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  article: ArticleType,
  status: Status
};

const initialState: State = {
  article: nullArticle,
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_ARTICLE_START:
      return {
        ...state,
        article: action.payload.article,
        status: STATUS_LOADING
      };
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article,
        status: STATUS_SUCCESS
      };
    case FETCH_ARTICLE_FAILURE:
      return {
        ...state,
        article: action.payload.article,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
