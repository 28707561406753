// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_ARTICLES_CATEGORY_START,
  FETCH_ARTICLES_CATEGORY_SUCCESS,
  FETCH_ARTICLES_CATEGORY_FAILURE
} from 'actions/newsletterCategoryActions';

import type { ArticleType } from 'types/Article';
import { nullLoadingArticle } from 'types/Article';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  articles: Array<ArticleType>,
  status: Status,
  articleNumber: number
};

const initialState: State = {
  articles: [],
  articleNumber: 10,
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_ARTICLES_CATEGORY_START:
      return {
        ...state,
        status: STATUS_LOADING,
        articles: [
          nullLoadingArticle
        ]
      };
    case FETCH_ARTICLES_CATEGORY_SUCCESS:
      return {
        ...state,
        articles: action.payload.articles,
        articleNumber: action.payload.articleNumber,
        status: STATUS_SUCCESS
      };
    case FETCH_ARTICLES_CATEGORY_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
