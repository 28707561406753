// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type CompetitionListElementType = {
  division: string,
  slug: string,
  recents: number,
  age: string,
  sexe: string,
  famille: string,
  order: number,
  first: {
    slug: string,
    title: string,
    src: string
  },
  StructureOrganisatrice: {
    id: number,
    nom: string
  }
};

export type CompetitionListCategoryType = {
  title: string,
  index: number,
  championships: Array<CompetitionListElementType>
};

export const nullCompetitionElement = {
  division: '',
  slug: '',
  recents: 0,
  age: '',
  sexe: '',
  famille: '',
  order: 0,
  first: {
    slug: '',
    title: '',
    src: ''
  },
  StructureOrganisatrice: {
    id: 0,
    nom: ''
  }
};

export type MatchType = {
  id_ovale: string,
  locaux: string,
  locaux_score: string,
  visiteurs: string,
  visiteurs_score: string,
  rencontre_id: number
};

export type ParticipantType = {
  embleme: string,
  id: number,
  nom: string,
  ovale_id: number,
  poule: string,
  poule_ordre: number,
  slug: string,
  url: string,
  url_type: string
};

export type CompetitionFromAgregateurType = {
  slug: string,
  participants: ParticipantType[],
  prevDay: {
    poules: Array<{
      pouleId: number,
      rencontres: Array<MatchType>
    }>,
    journee_id: string,
    nombre_rencontres: number,
    journee_date: string,
    journee_nom: string,
    phase_id: string,
    phase_nom: string
  },
  nextDay: {
    poules: Array<{
      pouleId: number,
      rencontres: Array<MatchType>
    }>,
    journee_id: boolean,
    nombre_rencontres: number,
    journee_date: string,
    journee_nom: string
  },
  ranking: { poules: any[] }
};

export type CompetitionFromBoType = SEOMetaType & {
  division: string,
  documents: {
    documents: Array<BlocContent>
  },
  medias: {
    content: Array<BlocContent>,
    media: Array<any>
  },
  sidebar: Array<BlocContent>,
  slug: string
};

export type CompetitionType = CompetitionFromAgregateurType & CompetitionFromBoType;

/**
 * type pour l'agrégateur
 */
type RawRencontreResultatFromAgregateur = {
  pointsDeMarque: number
};

type RawRencontreFromAgregateurType = {
  ordrePoule: number,
  id: string,
  Etat: {
    nom: string
  },
  RencontreResultatLocale: RawRencontreResultatFromAgregateur,
  RencontreResultatVisiteuse: RawRencontreResultatFromAgregateur,
  competitionEquipeLocaleId: number,
  competitionEquipeVisiteuseId: number
};

type RawJourneeFromAgregateur = {
  id: string,
  dateDebut: string,
  nom: string,
  Phase: {
    id: string,
    nom: string
  },
  RencontresStructures: RawRencontreFromAgregateurType[],
  RencontresRegroupements: RawRencontreFromAgregateurType[]
};

type RawClassementFromAgregateur = {
  bonusDefensif: number,
  bonusOffensif: number,
  essaiConcedes: number,
  essaiMarques: number,
  gagnes: number,
  goalAverage: number,
  joues: number,
  nuls: number,
  perdus: number,
  pointTerrain: number,
  pointsDeMarqueAcquis: number,
  regulationPointsTerrain: number
};

type RawDerniersResultatsFromAgregateurType = {
  id: string,
  dateEffective: string,
  competitionEquipeLocaleId: string,
  competitionEquipeVisiteuseId: string,
  RencontreResultatLocale: {
    pointsDeMarque: number
  },
  RencontreResultatVisiteuse: {
    pointsDeMarque: number
  }
};

export type RawCompetitionFromAgregateurType = {
  nom: string,
  identifiant: string,
  id: string,
  nextDay: RawJourneeFromAgregateur[],
  derniereRencontre: {
    Journee: RawJourneeFromAgregateur
  },
  derniereRencontreRegroupement: {
    Journee: RawJourneeFromAgregateur
  },
  CurrentPhase: {
    id: string,
    nom: string,
    ClassementsInterPoule: {
      positionInterPoule: number,
      Classements: {
        positionInterPoule: number,
        Classements: RawClassementFromAgregateur[]
      },
      Equipe: {
        id: string
      }
    },
    ClassementsIntraPoule: {
      ordrePoule: number,
      pouleId: string,
      Classements: {
        position: number,
        Classements: RawClassementFromAgregateur[],
        Equipe: {
          id: string
        }
      }
    }
  },
  Equipes: {
    id: string,
    DerniersResultats: RawDerniersResultatsFromAgregateurType[]
  },
  CompetitionPoule: {
    id: string,
    ordre: number
  },
  Structure: {
    CompetitionPoule: {
      id: string,
      identifiant: string,
      nom: string,
      embleme: string,
      StructureParent: {
        id: string
      }
    },
    Regroupement: {
      id: string,
      identifiant: string,
      nom: string,
      embleme: string,
      Structure: {
        id: string,
        nom: string
      }
    }
  }
};

type UnusedDataRawCompetitionFromBoType = {
  id: number,
  famille: string,
  officielle: string,
  nouveaux_resultats: number,
  date: string,
  date_gmt: string,
  guid: {
    rendered: string
  },
  modified: string,
  modified_gmt: string,
  status: string,
  type: string,
  link: string,
  excerpt: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  template: string,
  categories: Array<any>,
  tags: Array<any>,
  sexe: string,
  classe_age: string,
  classe_age_code: string,
  ovale_id: number,
  tags_list: Array<any>
};

export type RawCompetitionFromBoType = SEOMetaType & {
  liste_enfants: {
    documents: {
      content: {
        rendered: string,
        data: Array<BlocContent>
      }
    },
    medias: {
      content: {
        rendered: string,
        data: Array<BlocContent>
      },
      albums: Array<any>
    }
  },
  sidebar_content: {
    data: Array<BlocContent>
  },
  slug: string,
  title: {
    rendered: string,
    raw: string,
    data: Array<{
      type: string,
      text: string
    }>
  }
};

export type RawCompetitionType = RawCompetitionFromAgregateurType &
  UnusedDataRawCompetitionFromBoType &
  RawCompetitionFromBoType;

export const nullCompetitionFromAgregateur = {
  slug: '',
  participants: [],
  prevDay: {
    poules: [],
    journee_id: '',
    nombre_rencontres: 0,
    journee_date: '',
    journee_nom: '',
    phase_id: '',
    phase_nom: ''
  },
  nextDay: {
    poules: [],
    journee_id: false,
    nombre_rencontres: 0,
    journee_date: '',
    journee_nom: ''
  },
  ranking: { poules: [] }
};

export const nullCompetitionFromBo = {
  ...nullSeoMeta,
  division: '',
  documents: {
    documents: []
  },
  medias: {
    content: [],
    media: []
  },
  sidebar: [],
  slug: ''
};

export const nullUnusedDataRawCompetitionFromBo = {
  id: 0,
  famille: '',
  officielle: '',
  nouveaux_resultats: 0,
  date: '',
  date_gmt: '',
  guid: {
    rendered: ''
  },
  modified: '',
  modified_gmt: '',
  status: '',
  type: '',
  link: '',
  excerpt: {
    rendered: '',
    protected: true,
    raw: '',
    data: []
  },
  template: '',
  categories: [],
  tags: [],
  sexe: '',
  classe_age: '',
  classe_age_code: '',
  ovale_id: 0,
  tags_list: []
};

export const nullCompetitionType = {
  ...nullCompetitionFromAgregateur,
  ...nullCompetitionFromBo
};
