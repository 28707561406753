// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type PratiqueContact = {
  id: number,
  email: string,
  emailPublic: string,
  nom: string,
  prenom: string,
  numeroAffiliation: string,
  telPublic: string,
  fonction: string[]
};

export type PratiqueAdresse = {
  complement?: string,
  email?: string,
  id?: number,
  localite?: string,
  codePostal?: string,
  voie?: string,
  lieuDitBoitePostale?: string,
  latitude?: number,
  longitude?: number,
  refPays?: string,
  paysNom?: string,
  immeuble?: string,
  codeInsee?: string
};

export type PratiqueAgenda = {
  jour: number,
  jourNom: string,
  heureDebut?: string,
  heureFin?: string,
  Adresse: PratiqueAdresse
};

export type Pratique = {
  id: number,
  name: string,
  contact: PratiqueContact[],
  agenda: PratiqueAgenda[]
};

export type SearchClub = {
  'Adresse.codePostal': string,
  'Adresse.localite': string,
  'Adresse.position'?: { coordinates: [number, number] },
  acronyme: string,
  code: string,
  embleme: string,
  id: string,
  identifiant: string,
  nom: string,
  numeroDepartementPratique: string,
  pratiques: string[],
  _geoloc: { lat: number, lng: number },
  _geoDistance?: number,
  pratiques: string[]
};

export type CityPreview = {
  codePostal: string,
  departement: string,
  id: string,
  nom: string
};

export type ClubPreviewType = {
  img: string,
  name: string,
  searchName: string,
  long: number,
  lat: number,
  competitions: string[],
  practices: string[],
  adress: {
    complement: string,
    street: string,
    postalCode: string,
    locality: string
  },
  slug: string,
  _geoDistance?: number
};

export type ContactRowType = {
  category: string,
  members: Array<{
    name: string,
    role: string,
    email: string,
    phoneNumber: string,
    emailPublic: string,
    telPublic: string
  }>
};

export type ContactListType = Array<ContactRowType>;

export type ComiteFunctionType = {
  codeQualite: string,
  emailPublic: string,
  fonction: string,
  id: string,
  telPublic: string,
  Personne: {
    nom: string,
    numeroAffiliation: string,
    prenom: string,
    emailPublic: string,
    telPublic: string
  }
};

export type ClubType = SEOMetaType & {
  name: string,
  tags: Array<string>,
  teams: number,
  logo: string,
  adress: {
    complement: string,
    street: string,
    postalCode: string,
    locality: string
  },
  lat: number,
  long: number,
  practices: Array<string>,
  pratiques_full: Array<Pratique>,
  competitions: Array<string>,
  website: string,
  email: string,
  phoneNumber: string,
  contacts: ContactListType,
  facebook: string,
  twitter: string,
  sidebar: Array<BlocContent>,
  comite_directeur: Array<ComiteFunctionType>
};

export type PracticeType = {
  name: string,
  description: string,
  place: string
};

export type RawClubType = SEOMetaType & {
  id: number,
  date: string,
  date_gmt: string,
  guid: {
    rendered: string
  },
  modified: string,
  modified_gmt: string,
  slug: string,
  status: string,
  type: string,
  link: string,
  tel_pro: string,
  title: {
    rendered: string,
    raw: string,
    data: [
      {
        type: string,
        text: string
      }
    ]
  },
  excerpt: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  template: string,
  categories: Array<any>,
  tags: Array<any>,
  pratiques: Array<any>,
  siege_social: {
    id: number,
    author: string,
    status: string,
    date: string,
    date_utc: string,
    modified: string,
    modified_utc: string,
    venue: string,
    description: string,
    slug: string,
    address: string,
    city: string,
    country: string,
    province: string,
    zip: string,
    phone: string,
    website: string,
    email: string,
    latitude: number,
    longitude: number
  },
  terrains: Array<any>,
  website: string,
  embleme: string,
  pratiques_list: any[],
  competitions_list: any[],
  tags_list: string[],
  sidebar_content: {
    data: BlocContent[]
  },
  facebook: string,
  twitter: string,
  comite_directeur: Array<ComiteFunctionType>
};

export type RawClubPreviewType = {
  id: number,
  title: string,
  long_name: string,
  latitude: number,
  longitude: number,
  city: string,
  address: string,
  zip: string,
  country: string,
  embleme: string,
  slug: string,
  pratiques_list: {
    id: number,
    name: string
  }[],
  competitions_list: {
    id: number,
    nom: string
  }[]
};

export type RawPresidentsType = {
  items: Array<{
    personne: {
      personneId: number,
      numeroAffiliation: string,
      nom: string,
      prenom: string,
      email: string
    },
    fonction: string,
    codeQualite: ?string
  }>
};

export const nullClub: ClubType = {
  ...nullSeoMeta,
  name: '',
  tags: [],
  teams: 0,
  logo: '',
  adress: {
    complement: '',
    street: '',
    postalCode: '',
    locality: ''
  },
  lat: 0,
  long: 0,
  practices: [],
  pratiques_full: [],
  competitions: [],
  website: '',
  email: '',
  phoneNumber: '',
  contacts: [],
  facebook: '',
  twitter: '',
  sidebar: [],
  comite_directeur: []
};

export const nullPresidents: ContactRowType = {
  category: 'Direction',
  members: []
};

export const nullClubPreview: ClubPreviewType = {
  img: '',
  name: '',
  searchName: '',
  long: 0,
  lat: 0,
  competitions: [],
  practices: [],
  adress: {
    complement: '',
    street: '',
    postalCode: '',
    locality: ''
  },
  slug: 'slug'
};

export type Practice = {
  id: string,
  nom: string
};
