// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertArticleFromApi, convertCategoryFromApi, convertCategoryArticleFromApi } from 'services/Article';

function ArticleApi(executor: ApiExecutorType, staticExecutor: ApiExecutorType) {
  return {
    fetchArticleBySlug,
    fetchNewArticles,
    fetchArticlesByPage,
    fetchCategories,
    fetchArticlesLast,
    fetchArticleByCategory,
    fetchArticleByOneCategory
  };

  function fetchArticleBySlug(slug: string) {
    return executor.get(`/ffr/v1/posts?slug=${slug}`).then(response => convertArticleFromApi(response[0]));
  }

  function fetchNewArticles(after: Date) {
    return executor
      .get(`/ffr/v1/posts?after=${after.toISOString()}`)
      .then(response => response.map(a => convertArticleFromApi(a)));
  }

  function fetchArticlesByPage(page: number, per_page: number) {
    return executor
      .get(`/ffr/v1/posts?page=${page}&per_page=${per_page}`)
      .then(response => response.map(a => convertArticleFromApi(a)));
  }
  /**
   * Billboard
   */
  function fetchArticlesLast() {
    return staticExecutor.get(`/last_posts.json`)
          .then(response => response.map(a => convertArticleFromApi(a)))
          .catch( error => {
            return executor.get(`/ffr/v1/posts?page=1&per_page=6`)
              .then(response => response.map(a => convertArticleFromApi(a)));
            }
          );
  }

  function fetchCategories() {
    return staticExecutor.get(`/categories.json`)
            .then(response => response.map(category => convertCategoryFromApi(category)))
            .catch( error => {
              return executor.get(`/wp/v2/categories?per_page=100`)
                .then(response => response.map(category => convertCategoryFromApi(category)));
              }
            );
  }
 
  function fetchArticleByCategory(page: number, per_page: number, category: any) {
    return (
      executor
        .get(`/ffr/v1/posts?page=${page}&per_page=${per_page}&categories=${category.id}`)
        .then(response => {
          category.items = response.map(a => convertArticleFromApi(a));
          return {
            category: convertCategoryArticleFromApi(category),
            maxPage: 1
          };
        })
    );
  }

  function fetchArticleByOneCategory(page: number, per_page: number, category: any) {
    return (
      executor
        .getWithHeader(`/ffr/v1/posts?page=${page}&per_page=${per_page}&categories=${category.id}`)
        .then(response => ({
            articles: response.body.map(a => convertArticleFromApi(a)),
            articleNumber: response.headers['x-wp-total']
          }))
    );
  }
}

export default ArticleApi;
