// @flow

import type { ClubType } from 'types/Club';

export const FETCH_CLUB_START = 'FETCH_CLUB_START';
export const FETCH_CLUB_SUCCESS = 'FETCH_CLUB_SUCCESS';
export const FETCH_CLUB_FAILURE = 'FETCH_CLUB_FAILURE';

export type FETCH_CLUB_START_ACTION = {
  type: 'FETCH_CLUB_START',
  payload: {
    slug: string
  }
};

export type FETCH_CLUB_FAILURE_ACTION = {
  type: 'FETCH_CLUB_FAILURE'
};

export type FETCH_CLUB_SUCCESS_ACTION = {
  type: 'FETCH_CLUB_SUCCESS',
  payload: {
    club: ClubType
  }
};

export type Action = FETCH_CLUB_START_ACTION | FETCH_CLUB_FAILURE_ACTION | FETCH_CLUB_SUCCESS_ACTION;

export function fetchClub(slug: string): FETCH_CLUB_START_ACTION {
  return {
    type: FETCH_CLUB_START,
    payload: { slug }
  };
}

export function clubFetched(club: ClubType): FETCH_CLUB_SUCCESS_ACTION {
  return {
    type: FETCH_CLUB_SUCCESS,
    payload: { club }
  };
}

export function clubFetchError(error: any): FETCH_CLUB_FAILURE_ACTION {
  return {
    type: FETCH_CLUB_FAILURE
  };
}
