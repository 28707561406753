// @flow
import type { HomeCardType } from 'types/Settings';
import type { CardType } from 'types/PageList';
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type DocumentType = {
  id: number,
  title: string,
  mime_type: string,
  src: string,
  date: string,
  tags: string
};

export type MemberType = {
  id: number,
  title: string,
  role: string,
  roles: string[],
  email: string,
  telephone: string
};

export type AnnuaireType = {
  title: string,
  personnes: Array<{
    name: string,
    child: MemberType[]
  }>
};

export type DepartmentType = {
  id: number,
  letter: string,
  title: string,
  adress: {
    complement: string,
    street: string,
    postalCode: string,
    locality: string
  },
  mail: string,
  phone: string,
  personnes: MemberType[]
};

export type PageDetailType = SEOMetaType & {
  title: string,
  content: BlocContent[],
  slug: string,
  link: string,
  featured_media: {
    src: string,
    legend: string
  },
  niveau: number,
  documents: DocumentType[],
  annuaires?: AnnuaireType[],
  membersGroups?: Array<{
    name: string,
    child: MemberType[]
  }>,
  departmentsGroups?: DepartmentType[],
  sidebar_content: BlocContent[],
  template: string
};

export type RawPageDetailType = SEOMetaType & {
  title: string,
  description: string,
  slug: string,
  link: string,
  content: {
    data: BlocContent[]
  },
  title: {
    raw: string
  },
  parent: string,
  url: string,
  email: string,
  timezone: string,
  date_format: string,
  time_format: string,
  start_of_week: number,
  language: string,
  use_smilies: boolean,
  default_category: number,
  default_post_format: string,
  posts_per_page: number,
  default_ping_status: string,
  default_comment_status: string,
  logo: string,
  logo_footer: string,
  class_ligue: string,
  titre_liste_cards: string,
  liste_cards: CardType[],
  featured_media: {
    src: string,
    legend: string
  },
  'gerer-club': HomeCardType,
  'bulletin-officiel': HomeCardType,
  niveau: number,
  documents?: DocumentType[],
  personnes?: MemberType[],
  annuaires?: Array<{
    title: string,
    personnes: MemberType[]
  }>,
  personnes_departement?: DepartmentType[],
  sidebar_content: {
    data: BlocContent[]
  },
  template: string
};

export const nullPageDetail: PageDetailType = {
  ...nullSeoMeta,
  title: '',
  content: [],
  slug: '',
  link: '',
  featured_media: {
    src: '',
    legend: ''
  },
  niveau: 0,
  annuaires: [],
  documents: [],
  membersGroups: [],
  departmentsGroups: [],
  sidebar_content: [],
  template: ''
};
