// @flow
import React from 'react';
import { handleTagEvent } from 'utils/tagManagerUtils';
import type { liensRsType } from 'types/Settings';
import NewsletterSubscriberContainer from 'containers/footer/NewsletterSubscriberContainer';

export type StateProps = {
  titleLigue: string,
  liens_rs: liensRsType
};

const FooterTop = (props: StateProps) => {
  const {
    liens_rs: {
      fb_lien,
      twitter_lien,
      instagram_lien,
      youtube_lien,
      flickr_lien,
      linkedin_lien,
      twitch_lien,
      snapchat_lien,
      tik_tok_lien
    },
    titleLigue
  } = props;
  if (
    fb_lien === '' &&
    twitter_lien === '' &&
    instagram_lien === '' &&
    youtube_lien === '' &&
    flickr_lien === '' &&
    linkedin_lien === '' &&
    twitch_lien === '' &&
    snapchat_lien === '' &&
    tik_tok_lien === ''
  ) {
    return null;
  }
  return (
    <div className="container footer__top">
      <div className="row">
        <div className="col col-lg-3">
          <h5 className="mb-1">Nous suivre</h5>
          <div className="mt-3 mb-5">
            {props.liens_rs.fb_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={props.liens_rs.fb_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Facebook de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'facebook')}
              >
                <i className="icon icon-facebook" />
              </a>
            )}
            {props.liens_rs.twitter_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={props.liens_rs.twitter_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Twitter de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'twitter')}
              >
                <i className="icon icon-twitter" />
              </a>
            )}
            {props.liens_rs.instagram_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={props.liens_rs.instagram_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Instagram de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'instagram')}
              >
                <i className="icon icon-instagram" />
              </a>
            )}
            {props.liens_rs.youtube_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={props.liens_rs.youtube_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Youtube de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'youtube')}
              >
                <i className="icon icon-youtube" />
              </a>
            )}
            {props.liens_rs.flickr_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={props.liens_rs.flickr_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Flickr de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'flickr')}
              >
                <i className="icon icon-flickr" />
              </a>
            )}
            {props.liens_rs.linkedin_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={props.liens_rs.linkedin_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Linkedin de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'linkedin')}
              >
                <i className="icon icon-linkedin" />
              </a>
            )}
            {props.liens_rs.twitch_lien !== '' && (
              <a
                className="link-icon is-dark mb-2"
                href={twitch_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Twitch de ${titleLigue}`}
                onClick={handleTagEvent('social wall', 'suivre reseau social', 'twitch')}
              >
                <i className="icon icon-twitch" />
              </a>
            )}
            {props.liens_rs.snapchat_lien !== '' && (
              <a
                className="link-icon is-dark mb-2"
                href={snapchat_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Snapchat de ${titleLigue}`}
                onClick={handleTagEvent('social wall', 'suivre reseau social', 'snapchat')}
              >
                <i className="icon icon-snapchat" />
              </a>
            )}
            {props.liens_rs.tik_tok_lien !== '' && (
              <a
                className="link-icon is-dark mb-2"
                href={tik_tok_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page TikTok de ${titleLigue}`}
                onClick={handleTagEvent('social wall', 'suivre reseau social', 'tiktok')}
              >
                <i className="icon icon-tik-tok" />
              </a>
            )}
          </div>
        </div>
        <NewsletterSubscriberContainer />
      </div>
      <hr />
    </div>
  );
};

export default FooterTop;
