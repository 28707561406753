// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type JobDetailType = SEOMetaType & {
  id: number,
  title: string,
  content: Array<BlocContent>,
  slug: string,
  link: string,
  city: string,
  jobtype: string,
  region: string,
  type_contrat: string,
  date_cloture: string,
  email: string,
  structure: {
    name: string,
    embleme: string,
    website: string,
  },
  domaine_emploi: string,
  featured_pdf: string,
  sidebar_content: Array<BlocContent>,
};

export type RawJobDetailType = SEOMetaType & {
  id: number,
  title: string,
  description: string,
  slug: string,
  link: string,
  content: {
    data: Array<BlocContent>
  },
  title: {
    raw: string
  },
  meta: {
    emailContact: string,
  },
  status: string,
  language: string,
  ville: string,
  jobtype: string,
  region: string,
  type_contrat: string,
  date_cloture: string,
  structure: {
    name: string,
    embleme: string,
    website: string,
  },
  domaine_emploi: string,
  featured_pdf: string,
  sidebar_content: {
    data: Array<BlocContent>
  },
};

export const nullJobDetail: JobDetailType = {
  ...nullSeoMeta,
  id: 0,
  title: '',
  content: [],
  slug: '',
  link: '',
  featured_pdf: '',
  city: '',
  jobtype: '',
  region: '',
  type_contrat: '',
  date_cloture: '',
  email: '',
  structure: {
    name: '',
    embleme: '',
    website:'',
  },
  domaine_emploi: '',
  sidebar_content: [],
};
