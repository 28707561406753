// @flow

import { fork, spawn, all } from 'redux-saga/effects';
import { BASE_URL, OVALE_BASE_URL, STATIC_BASE_URL } from 'constants/api';
import api from 'infrastructure/http/index';
import hitTagsSaga from 'sagas/hitTagsSaga.js';
import eventsSaga from 'sagas/eventsSaga.js';
import tagsSaga from 'sagas/tagsSaga.js';
import matchResultSaga from 'sagas/matchResultSaga.js';
import instagramSaga from 'sagas/instagramSaga';
import facebookSaga from 'sagas/facebookSaga';
import twitterSaga from 'sagas/twitterSaga';
import articleSaga from 'sagas/articleSaga.js';
import newsletterSaga from 'sagas/newsletterSaga.js';
import menuSaga from 'sagas/menuSaga';
import clubSaga from 'sagas/clubSaga.js';
import competitionsSaga from 'sagas/competitionsSaga.js';
import newsletterCategorySaga from 'sagas/newsletterCategorySaga.js';
import partenaireSaga from 'sagas/partenaireSaga.js';
import partnerSaga from 'sagas/partnerSaga.js';
import settingsSaga from 'sagas/settingsSaga.js';
import pageSaga from 'sagas/pageSaga.js';
import sidebarSaga from 'sagas/sidebarSaga';
import competitionSaga from 'sagas/competitionSaga';
import faqSaga from 'sagas/faqSaga';
import searchSaga from 'sagas/searchSaga';
import newsletterSubscriptionSaga from 'sagas/newsletterSubscriptionSaga';
import youtubeSaga from 'sagas/youtubeSaga';
import jobSaga from 'sagas/jobSaga';
import contactForm7Saga from 'sagas/contactForm7Saga';
import tagArticleSaga from 'sagas/tagArticleSaga.js';
import albumSaga from 'sagas/albumSaga';

export type GeneratorType = Generator<*, *, *>;

export default function*(): GeneratorType {
  yield fork(bootstrap);
}

function* bootstrap(): GeneratorType {
  const apiExecutor = api(BASE_URL);
  const ovaleApiExecutor = api(OVALE_BASE_URL);
  const staticApiExecutor = api(STATIC_BASE_URL);

  try {
    yield all([
      spawn(hitTagsSaga()),
      spawn(eventsSaga(apiExecutor)),
      spawn(matchResultSaga(apiExecutor)),
      spawn(instagramSaga(apiExecutor)),
      spawn(facebookSaga(apiExecutor)),
      spawn(twitterSaga(apiExecutor)),
      spawn(articleSaga(apiExecutor, staticApiExecutor)),
      spawn(newsletterSaga(apiExecutor, staticApiExecutor)),
      spawn(menuSaga(apiExecutor, staticApiExecutor)),
      spawn(clubSaga(ovaleApiExecutor, apiExecutor)),
      spawn(competitionsSaga(apiExecutor)),
      spawn(tagsSaga(apiExecutor)),
      spawn(newsletterCategorySaga(apiExecutor, staticApiExecutor)),
      spawn(partnerSaga(apiExecutor, staticApiExecutor)),
      spawn(settingsSaga(apiExecutor, staticApiExecutor)),
      spawn(pageSaga(apiExecutor)),
      spawn(sidebarSaga(apiExecutor).sidebarSaga),
      spawn(competitionSaga(apiExecutor)),
      spawn(faqSaga(apiExecutor)),
      spawn(searchSaga(apiExecutor)),
      spawn(newsletterSubscriptionSaga(apiExecutor)),
      spawn(youtubeSaga(apiExecutor)),
      spawn(partenaireSaga(apiExecutor)),
      spawn(jobSaga(apiExecutor)),
      spawn(contactForm7Saga(apiExecutor)),
      spawn(tagArticleSaga(apiExecutor)),
      spawn(albumSaga(apiExecutor)),
    ]);
  } catch (e) {
    // @TODO
  }
}
