// @flow
import Menu from 'components/header/Menu';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';

export default connect(
  (state: StateType, ...ownProps: any) => ({
    liens_rs: state.settingsState.liens_rs,
    logo: state.settingsState.logo,
    menuElements: state.menuState.header,
    titleLigue: state.settingsState.title
  }),
  null,
  null,
  { pure: false }
)(Menu);
