// @flow

import type { MatchForMatchOfTheWeekType, MatchResultType } from 'types/MatchResult';

export const FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START = 'FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START';
export const FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_SUCCESS = 'FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_SUCCESS';
export const FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_FAILURE = 'FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_FAILURE';
export const FETCH_MATCHRESULT_START = 'FETCH_MATCHRESULT_START';
export const FETCH_MATCHRESULT_SUCCESS = 'FETCH_MATCHRESULT_SUCCESS';
export const FETCH_MATCHRESULT_FAILURE = 'FETCH_MATCHRESULT_FAILURE';

export type FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START_ACTION = {
  type: 'FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START',
  payload: {
    structure: number,
    dateMinusAWeek: string,
    datePlusAWeek: string
  }
};

export type FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_FAILURE_ACTION = {
  type: 'FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_FAILURE'
};

export type FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_SUCCESS_ACTION = {
  type: 'FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_SUCCESS',
  payload: {
    results: MatchForMatchOfTheWeekType[]
  }
};

export type FETCH_MATCHRESULT_START_ACTION = {
  type: 'FETCH_MATCHRESULT_START',
  payload: {
    match_semaine: number
  }
};

export type FETCH_MATCHRESULT_FAILURE_ACTION = {
  type: 'FETCH_MATCHRESULT_FAILURE'
};

export type FETCH_MATCHRESULT_SUCCESS_ACTION = {
  type: 'FETCH_MATCHRESULT_SUCCESS',
  payload: {
    result: MatchResultType
  }
};

export type Action =
  | FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START_ACTION
  | FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_FAILURE_ACTION
  | FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_SUCCESS_ACTION
  | FETCH_MATCHRESULT_START_ACTION
  | FETCH_MATCHRESULT_FAILURE_ACTION
  | FETCH_MATCHRESULT_SUCCESS_ACTION;

export function fetchAllMatchesForMatchOfTheWeek(
  structure: number,
  dateMinusAWeek: string,
  datePlusAWeek: string
): FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START_ACTION {
  return {
    type: FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START,
    payload: {
      structure,
      dateMinusAWeek,
      datePlusAWeek
    }
  };
}

export function allMatchesForMatchOfTheWeekFetched(
  results: MatchForMatchOfTheWeekType[]
): FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_SUCCESS_ACTION {
  return {
    type: FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_SUCCESS,
    payload: { results }
  };
}

export function allMatchesForMatchOfTheWeekFetchError(): FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_FAILURE_ACTION {
  return {
    type: FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_FAILURE
  };
}

export function fetchMatchResult(match_semaine: number): FETCH_MATCHRESULT_START_ACTION {
  return {
    type: FETCH_MATCHRESULT_START,
    payload: { match_semaine }
  };
}

export function matchResultFetched(result: MatchResultType): FETCH_MATCHRESULT_SUCCESS_ACTION {
  return {
    type: FETCH_MATCHRESULT_SUCCESS,
    payload: { result }
  };
}

export function matchResultFetchError(): FETCH_MATCHRESULT_FAILURE_ACTION {
  return {
    type: FETCH_MATCHRESULT_FAILURE
  };
}
