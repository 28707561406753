// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import ClubApi from 'api/club/ClubApi';
import {
  FETCH_CLUBS_START,
  clubsFetched,
  clubsFetchError,
  FETCH_PRACTICES_START,
  practicesFetchError,
  practicesFetched,
  FETCH_COMPETITIONS_FILTER_START,
  competitionsFetched,
  competitionsFetchError,
} from 'actions/clubsActions';
import { FETCH_CLUB_START, clubFetched, clubFetchError } from 'actions/clubActions';

import type { FETCH_CLUBS_START_ACTION, FETCH_PRACTICES_START_ACTION, FETCH_COMPETITIONS_FILTER_START_ACTION } from 'actions/clubsActions';
import type { FETCH_CLUB_START_ACTION } from 'actions/clubActions';

export default function (ovaleApiExecutor: ApiExecutorType, ffrApiExecutor: ApiExecutorType) {
  const clubApi = new ClubApi(ffrApiExecutor);

  return function* newsSaga(): GeneratorType {
    yield takeLatest(FETCH_CLUBS_START, fetchClubs);
    yield takeLatest(FETCH_CLUB_START, fetchClub);
    yield takeLatest(FETCH_PRACTICES_START, fetchPractices);
    yield takeLatest(FETCH_COMPETITIONS_FILTER_START, fetchCompetitions);
  };

  function* fetchClubs(action: FETCH_CLUBS_START_ACTION): Saga<void> {
    try {
      const clubs = yield call(clubApi.getClubs);
      yield put(clubsFetched(clubs));
    } catch (e) {
      yield put(clubsFetchError(e));
    }
  }

  function* fetchPractices(action: FETCH_PRACTICES_START_ACTION) {
    try {
      const practices = yield call(clubApi.getPractices, action.payload.ligue);

      yield put(practicesFetched(practices));
    } catch (e) {
      yield put(practicesFetchError());
    }
  }

  function* fetchCompetitions(action: FETCH_COMPETITIONS_FILTER_START_ACTION) {
    try {
      const { Competitions, Structure: { nom } } = yield call(clubApi.getCompetitions, action.payload.ligue);

      yield put(competitionsFetched({ Competitions, Ligue: { nom } }));
    } catch (e) {
      yield put(competitionsFetchError());
    }
  }

  function* fetchClub(action: FETCH_CLUB_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const club = yield call(clubApi.getClub, slug);

      if (!club.name) {
        throw new Error('No club with slug ' + slug);
      }

      yield put(clubFetched(club));
    } catch (e) {
      yield put(clubFetchError());
    }
  }
}
