// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SourceSetType } from 'types/Article';

export type RawAlbumType = {
  id: number,
  date: Date,
  modifiied: string,
  slug: string,
  status: string,
  type: string,
  link: string,
  title: {
    rendered: string
  },
  content: {
    data: BlocContent[]
  },
  excerpt: {
    data: BlocContent[],
    raw: string
  },
  featured_media: {
    src: string,
    sizes: SourceSetType
  },
  template: string,
  meta: {
    rencontre: string
  },
  meta_title: string,
  meta_description: string,
  niveau: number,
  filtres_albums: number[],
  saison: number[],
  breadcrumb: Array<string | { name: string, to: string }>,
  parent_link: string
};

export type AlbumType = {
  id: number,
  date: Date,
  title: string,
  slug: string,
  content: {
    data: BlocContent[]
  },
  excerpt: {
    data: BlocContent[],
    raw: string
  },
  itemsCount: number,
  featured_media: {
    src: string,
    sizes: SourceSetType
  },
  meta_title: string,
  meta_description: string,
  filtres_albums: number[],
  saison: number[],
  breadcrumb: Array<string | { name: string, to: string }>,
  parent_link: string
};

export const nullAlbum: AlbumType = {
  id: 0,
  date: new Date(),
  title: '',
  slug: '',
  content: {
    data: []
  },
  excerpt: {
    data: [],
    raw: ''
  },
  itemsCount: 0,
  featured_media: {
    src: '',
    sizes: {}
  },
  meta_title: '',
  meta_description: '',
  filtres_albums: [],
  saison: [],
  breadcrumb: [],
  parent_link: ''
};
