// @flow
import FooterBottom from 'components/footer/FooterBottom';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';

export default connect(
  (state: StateType) => ({
    contacts: state.settingsState.contacts,
    footerElements: state.menuState.footer,
    logo_footer: state.settingsState.logo_footer,
    title: state.settingsState.title
  })
)(FooterBottom);
