// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import NewsletterApi from 'api/newsletter/NewsletterApi';
import { convertObjectToUrlEncoded } from 'utils/objectUtils';

import {
  FETCH_NEWSLETTER_OPTIONS_START,
  newsletterOptionsFetched,
  newsletterOptionsFetchError
} from 'actions/newsletterSubscriptionActions';
import type {
  FETCH_NEWSLETTER_OPTIONS_START_ACTION,
  SUBSCRIBE_NEWSLETTER_START_ACTION
} from 'actions/newsletterSubscriptionActions';

import {
  SUBSCRIBE_NEWSLETTER_START,
  newsletterSubscribed,
  newsletterSubscribeError
} from 'actions/newsletterSubscriptionActions';
import { displayModal } from 'actions/modalActions';

export default function(apiExecutor: ApiExecutorType) {
  const newsletterSubscriptionApi = new NewsletterApi(apiExecutor);

  return function* newsletterSaga(): GeneratorType {
    yield takeLatest(FETCH_NEWSLETTER_OPTIONS_START, fetchOptions);
    yield takeLatest(SUBSCRIBE_NEWSLETTER_START, subscribeToNewsletter);
  };

  function* fetchOptions(action: FETCH_NEWSLETTER_OPTIONS_START_ACTION): Saga<void> {
    try {
      const { campaignMonitorKey } = action.payload;
      const options = yield call(newsletterSubscriptionApi.fetchOptions, campaignMonitorKey);
      yield put(newsletterOptionsFetched(options));
    } catch (e) {
      yield put(newsletterOptionsFetchError());
    }
  }

  function* subscribeToNewsletter(action: SUBSCRIBE_NEWSLETTER_START_ACTION): Saga<void> {
    try {
      const { subscription, campaignMonitorKey } = action.payload;
      const request = yield call(
        newsletterSubscriptionApi.subscribeToNewsletter,
        convertObjectToUrlEncoded(subscription),
        campaignMonitorKey
      );

      if (request.error) {
        // Code 208 -> Email Address exists in unconfirmed list. Subscriber is not added.
        if (request.Code === 208) {
          const wording = `Vous êtes déjà inscrit mais vous n’avez pas validé votre adresse.
          Nous vous invitons à consulter vos emails.`;

          return yield put(displayModal('NEWSLETTER_SUBSCRIPTION_FAILED', { wording }, true));
        } else if (request.Code === 206) {
          const wording = `Vous avez déjà été inscrit mais vous avez été désinscrit de la liste.
          Il ne nous est pas possible de vous réabonner.`;
          return yield put(displayModal('NEWSLETTER_SUBSCRIPTION_FAILED', { wording }, true));
        }

        return yield put(displayModal('NEWSLETTER_SUBSCRIPTION_FAILED', {}, true));
      }

      yield put(newsletterSubscribed());
      return yield put(displayModal('NEWSLETTER_SUBSCRIPTION_SUCCESS'));
    } catch (e) {
      yield put(newsletterSubscribeError());
      return yield put(displayModal('NEWSLETTER_SUBSCRIPTION_FAILED'));
    }
  }
}
