// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { ClubPreviewType, ClubType, Practice } from 'types/Club';
import { convertClubPreviewFromApi, convertClubFromApi } from 'services/Club';
import { AGREGATOR_URL } from 'constants/api';

function ClubApi(executor: ApiExecutorType) {
  return { getClubs, getClub, getPractices, getCompetitions };

  function getClubs(): Promise<Array<ClubPreviewType>> {
    return executor.get('/ffr/v1/clubssearch', {
      timeout: 200000
    }).then(response => Promise.all(response.map(convertClubPreviewFromApi)));
  }

  function getClub(slug: string): Promise<ClubType> {
    return executor.get(`/ffr/v1/clubs?slug=${slug}`).then(response => convertClubFromApi(response[0]));
  }

  function getPractices(ligue: number): Promise<Array<Practice>> {
    return fetch(AGREGATOR_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query:
          `query {
            Pratiques(Ligue: { id: ${ligue} }) {
              id
              nom
            }
          }`
      }),
    })
      .then(res => res.json())
      .then(({ data, errors }) => {
        if (errors) {
          throw errors[0];
        }

        return data.Pratiques || [];
      });
  }

  function getCompetitions(ligue: number): Promise<Array<Practice>> {
    return fetch(AGREGATOR_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query:
          `query {
            Structure(id: ${ligue}) {
              nom
            }
            
            Competitions(Ligue: { id: ${ligue} }) {
              id
              nom

              StructureOrganisatrice {
                nom
              }
            }
          }`
      }),
    })
      .then(res => res.json())
      .then(({ data, errors }) => {
        if (errors) {
          throw errors[0];
        }

        return data;
      });
  }
}
export default ClubApi;
