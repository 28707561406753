// @flow

import { APP_INCREASE_HIGHLIGHTED_POST_MODAL_COUNT, APP_SET_SHOW_ALERT_INFO } from 'actions/appActions';
import { APP_SET_SHOW_HIGHLIGHTED_POST_MODAL, APP_RESET_HIGHLIGHTED_POST_MODAL_COUNT } from 'actions/appActions';
import { nullHighlightedPost, type HighLightedPostType } from 'types/Settings';

import type { ActionType } from 'types/Actions';

export type State = {
  showAlertInfo: string,
  highlightedPostModalCount: number,
  showHighlightedPostModal: HighLightedPostType
};

const initialState: State = {
  showAlertInfo: '',
  highlightedPostModalCount: 0,
  showHighlightedPostModal: nullHighlightedPost
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case APP_SET_SHOW_HIGHLIGHTED_POST_MODAL:
      return {
        ...state,
        showHighlightedPostModal: action.payload.showHighlightedPostModal
      };
    case APP_INCREASE_HIGHLIGHTED_POST_MODAL_COUNT:
      return {
        ...state,
        highlightedPostModalCount: state.highlightedPostModalCount + 1
      };
    case APP_RESET_HIGHLIGHTED_POST_MODAL_COUNT:
      return {
        ...state,
        highlightedPostModalCount: 0
      };
    case APP_SET_SHOW_ALERT_INFO:
      return {
        ...state,
        showAlertInfo: action.payload.showAlertInfo
      };
    default:
      return state;
  }
}
