// @flow

import { STATUS_LOADING, STATUS_DEFAULT, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_COMPETITION_START,
  FETCH_COMPETITION_SUCCESS,
  FETCH_COMPETITION_FAILURE
} from 'actions/competitionActions';

import { nullCompetitionType, type CompetitionType } from 'types/Competition';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  competition: CompetitionType,
  status: Status
};

const initialState: State = {
  competition: nullCompetitionType,
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_COMPETITION_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_COMPETITION_SUCCESS:
      return {
        ...state,
        competition: action.payload.competition,
        status: STATUS_SUCCESS
      };
    case FETCH_COMPETITION_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
