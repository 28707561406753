// @flow
import type {
  RawArticleType,
  ArticleType,
  RawCategoryType,
  CategoryType,
  RawCategoryArticleType,
  CategoryArticleType
} from 'types/Article';
import { nullArticle, nullCategory, nullCategoryArticle } from 'types/Article';
import { mergeIntoDefault } from 'utils/objectUtils';
import { convertEncodedHTML } from 'utils/convertUtils';

export function convertArticleFromApi(rawArticle: RawArticleType): ArticleType {
  if (!rawArticle) {
    return nullArticle;
  }

  const {
    date,
    title,
    content,
    tags_list,
    slug,
    featured_media,
    featured_video,
    label,
    meta: {
      _actualiteFFRUrlCanonical
    },
    sidebar_content
  } = rawArticle;

  let illustration_finale = null;
  if (typeof featured_media !== 'number') {
    if (featured_media && featured_media.sizes && featured_media.sizes.medium_large && featured_media.sizes.medium_large.src) {
      illustration_finale = featured_media.sizes.medium_large.src;
    } else {
      illustration_finale = featured_media.src;
    }
  }
  return mergeIntoDefault(nullArticle, {
    date: new Date(date),
    label: {
      name: convertEncodedHTML(label.name),
      slug: label.slug
    }, 
    caption: typeof featured_media === 'number' ? null : convertEncodedHTML(featured_media.legend),
    title: title.raw,
    srcset: typeof featured_media === 'number' ? null : featured_media.sizes,
    illustration: illustration_finale,
    tags: tags_list,
    blocs: content.data,
    illustration_video: featured_video.data,
    twitter: null,
    facebook: null,
    ffr_url_canonical: _actualiteFFRUrlCanonical,
    sidebar: sidebar_content ? sidebar_content.data : [],
    slug
  });
}

export function convertCategoryFromApi(rawCategory: RawCategoryType): CategoryType {
  if (!rawCategory) {
    return nullCategory;
  }

  const { id, name, slug } = rawCategory;

  return mergeIntoDefault(nullCategory, {
    id,
    name: convertEncodedHTML(name),
    slug
  });
}

export function convertCategoryArticleFromApi(rawCategory: RawCategoryArticleType): CategoryArticleType {
  if (!rawCategory) {
    return nullCategoryArticle;
  }

  const { id, name, slug, items } = rawCategory;

  return mergeIntoDefault(nullCategoryArticle, {
    id,
    name: convertEncodedHTML(name),
    slug,
    items
  });
}
