// @flow
import React, { PureComponent } from 'react';
import 'components/footer/partners/Partners.css';
import { MOBILE_SIZE } from 'constants/app';
import LazyLoad from 'components/fragments/LazyLoad';
import type { PartnerType } from 'types/Partner';

type Props = {
  partners: PartnerType[]
};

type State = {
  current: number,
  isMobile: boolean,
};

class Partners extends PureComponent<Props, State> {
  timerID: IntervalID;
  constructor(props: Props) {
    super(props);

    this.state = {
      current: 0,
      isMobile: false,
    };
  }

  slidePartners = () => {
    const { partners } = this.props;
    const { current } = this.state;
    const sliderNumber = this._getNumberOfSlide();

    const currentValue = current + sliderNumber < partners.length ? current + sliderNumber : 0;

    this.setState({
      current: currentValue
    });
  }

  _getNumberOfSlide = () => {
    const { isMobile } = this.state;

    if (isMobile) {
      return 1;
    }

    return 5;
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', () => this.updateDimensions());

    this.timerID = setInterval(() => this.slidePartners(), 3500);
  }

  updateDimensions = () => {
    const { isMobile } = this.state;
    if (window.innerWidth <= MOBILE_SIZE && !isMobile) {
      this.setState({ isMobile: true });
    } else if (isMobile && window.innerWidth > MOBILE_SIZE) {
      this.setState({ isMobile: false });
    }
  }
  
  componentWillUnmount() {
    clearInterval(this.timerID);
    window.removeEventListener('resize', () => this.updateDimensions());
  }

  renderPartners = () => {
    const { partners } = this.props;
    const { current } = this.state;

    return partners.map<any>((partner, index) => {
      if (index >= current && index < current + this._getNumberOfSlide()) {
        return (
          <li
            key={index}
          >
            <a 
              title={`Voir le site du partenaire : ${partner.title} (nouvel onglet)`} 
              href={partner.link} rel="noopener noreferrer" 
              target={partner.target}>
                <img src={partner.illustration} alt={partner.title} />
            </a>
          </li>
        );
      }
      return null;
    });
  };

  render() {
    return (
      <LazyLoad isWhite>
        <ul className="partenaire partner--ligue">{this.renderPartners()}</ul>
      </LazyLoad>
    );
  }
}

export default Partners;
