// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertEventPreviewFromApi, convertEventFromApi } from 'services/Event';

function EventApi(executor: ApiExecutorType) {
  return { fetchEvents, fetchEvent, fetchEventsPreview };

  function fetchEvents(from: Date, to: Date) {
    from.setDate(from.getDate() + 1);
    to.setDate(to.getDate() + 1);
    return executor
      .get(`/ffr/v1/events?start_date=${from.toISOString()}&end_date=${to.toISOString()}&per_page=300`)
      .then(response => {
        return response.events.map(event => convertEventFromApi(event));
      });
  }

  function fetchEventsPreview() {
    return executor
      .get('/ffr/v1/events')
      .then(response => response.events.map(event => convertEventPreviewFromApi(event)));
  }

  function fetchEvent(slug: string) {
    return executor.get(`/ffr/v1/events/by-slug/${slug}`).then(response => convertEventFromApi(response));
  }
}

export default EventApi;
