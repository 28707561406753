// @flow
export function convertEncodedHTML (name?: string):string {

  let parser = document.createElement('p');
  parser.innerHTML = (name ? name : '');
  return parser.innerHTML.replace(/&lt;/g,">")
                        .replace(/&gt;/g,"<")
                        .replace(/&amp;/g,"&")
                        .replace(/&#8211;/g, "-");
}

/**
 * @function parseStyles
 * Parses a string of inline styles into a javascript object with casing for react
 *
 * @param {string} styles
 * @returns {Object}
 */
export const convertCSS = (styles: string) => styles
    .split(';')
    .filter(style => style.split(':')[0] && style.split(':')[1])
    .map(style => [
        style.split(':')[0].trim().replace(/-./g, c => c.substr(1).toUpperCase()),
        style.split(':').slice(1).join(':').trim(),
    ])
    .reduce((styleObj, style) => ({
        ...styleObj,
        [style[0]]: style[1],
    }), {});
