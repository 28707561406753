// @flow

import {
  FILTER_OPTIONS_SET_QUERY,
  FILTER_OPTIONS_SET_COMPETITON,
  FILTER_OPTIONS_SET_PRACTICE,
  FILTER_OPTIONS_SET_DISTANCE,
  REINITIALISE_SEARCH,
  FILTER_OPTIONS_ACTIVATE_DISTANCE
} from 'actions/filterOptionsActions';

import type { ActionType } from 'types/Actions';

export type State = {
  query: string,
  competitions: Array<string>,
  practices: Array<string>,
  distance: string,
  activateDistance: boolean
};

const initialState: State = {
  query: '',
  competitions: [],
  practices: [],
  distance: '',
  activateDistance: false
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FILTER_OPTIONS_SET_QUERY:
      return {
        ...state,
        query: action.payload.query
      };
    case FILTER_OPTIONS_SET_COMPETITON:
      return {
        ...state,
        competitions: action.payload.competitions
      };
    case FILTER_OPTIONS_SET_PRACTICE:
      return {
        ...state,
        practices: action.payload.practices
      };
    case FILTER_OPTIONS_SET_DISTANCE:
      return {
        ...state,
        distance: action.payload.distance
      };
    case REINITIALISE_SEARCH:
      return {
        ...state,
        query: '',
        competitions: [],
        practices: [],
        distance: '',
        activateDistance: false
      };
    case FILTER_OPTIONS_ACTIVATE_DISTANCE:
      return {
        ...state,
        activateDistance: action.payload.activateDistance
      };
    default:
      return state;
  }
}
