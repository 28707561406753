// @flow
import type {
  RawClubType,
  ClubType,
  RawClubPreviewType,
  ClubPreviewType,
  RawPresidentsType,
  ContactRowType
} from 'types/Club';
import { nullClub, nullPresidents, nullClubPreview } from 'types/Club';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertClubFromApi(rawClub: RawClubType, presidents?: ContactRowType): ClubType {
  if (!rawClub) {
    return nullClub;
  }

  const {
    title: { raw },
    website,
    siege_social: { email, venue, address, city, zip, latitude, longitude },
    embleme,
    pratiques_list,
    competitions_list,
    sidebar_content,
    facebook,
    twitter,
    tel_pro,
    comite_directeur,
    meta_title,
    meta_description
  } = rawClub;

  return mergeIntoDefault(nullClub, {
    meta_title,
    meta_description,
    name: raw,
    tags: [],
    teams: 0,
    logo: embleme ? embleme : '/img/logo/placeholder-club.png',
    adress: {
      venue,
      street: address,
      postalCode: zip,
      locality: city
    },
    lat: latitude,
    long: longitude,
    practices: pratiques_list.map(p => p.name),
    pratiques_full: pratiques_list,
    competitions: competitions_list.map(c => c.nom),
    website: website,
    email,
    phoneNumber: tel_pro,
    contacts: presidents ? [presidents] : [],
    facebook: facebook ? facebook : '',
    twitter: twitter ? twitter : '',
    sidebar: sidebar_content.data,
    comite_directeur: comite_directeur ? comite_directeur.filter(member => !!member.Personne) : []
  });
}

export function convertClubPreviewFromApi(rawClubPreview: RawClubPreviewType): ClubPreviewType {
  if (!rawClubPreview) {
    return nullClubPreview;
  }

  const {
    title,
    long_name,
    latitude,
    longitude,
    city,
    address,
    zip,
    embleme,
    slug,
    pratiques_list,
    competitions_list
  } = rawClubPreview;

  const adress = mergeIntoDefault(nullClubPreview.adress, {
    complement: '',
    street: address,
    postalCode: zip,
    locality: city
  });

  return mergeIntoDefault(nullClubPreview, {
    img: embleme ? embleme : '/img/logo/placeholder-club.png',
    name: title,
    searchName: long_name,
    long: longitude,
    lat: latitude,
    practices: pratiques_list.map(p => p.name),
    competitions: competitions_list.map(c => c.nom),
    adress,
    slug: slug
  });
}

export function convertPresidentsFromApi(rawPresidents: RawPresidentsType): ContactRowType {
  if (!rawPresidents) {
    return nullPresidents;
  }

  return mergeIntoDefault(nullPresidents, {
    category: 'Direction',
    members: rawPresidents.items.map(item => ({
      name: `${item.personne.prenom} ${item.personne.prenom}`,
      role: 'Président',
      email: `${item.personne.email}`,
      phoneNumber: '00 00 00 00 00'
    }))
  });
}
