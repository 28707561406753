// @flow

import { STATUS_LOADING, STATUS_DEFAULT, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { CompetitionListElementType } from 'types/Competition';
import {
  FETCH_COMPETITIONS_START,
  FETCH_COMPETITIONS_ELEMENTS_START,
  FETCH_COMPETITIONS_ELEMENTS_SUCCESS,
  FETCH_COMPETITIONS_ELEMENTS_FAILURE,
  FETCH_COMPETITIONS_SUCCESS,
  FETCH_COMPETITIONS_FAILURE
} from 'actions/competitionsActions';

import type { CompetitionLayoutType } from 'types/CompetitionLayout';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  competitions: Array<CompetitionLayoutType>,
  competitionsElements: Array<CompetitionListElementType>,
  status: Status
};

const initialState: State = {
  competitions: [],
  competitionsElements: [],
  status: STATUS_DEFAULT
};

export default function (state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_COMPETITIONS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_COMPETITIONS_ELEMENTS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_COMPETITIONS_SUCCESS:
      return {
        ...state,
        competitions: action.payload.competitions,
        status: STATUS_SUCCESS
      };
    case FETCH_COMPETITIONS_ELEMENTS_SUCCESS:
      return {
        ...state,
        competitionsElements: action.payload.competitionsElements,
        status: STATUS_SUCCESS
      };
    case FETCH_COMPETITIONS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_COMPETITIONS_ELEMENTS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
