// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import MatchResultApi from 'api/matchResult/MatchResultApi';
import {
  FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START,
  allMatchesForMatchOfTheWeekFetched,
  allMatchesForMatchOfTheWeekFetchError,
  FETCH_MATCHRESULT_START,
  matchResultFetched,
  matchResultFetchError
} from 'actions/matchResultActions';
import type {
  FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START_ACTION,
  FETCH_MATCHRESULT_START_ACTION
} from 'actions/matchResultActions';
export default function(apiExecutor: ApiExecutorType) {
  const matchResultApi = new MatchResultApi(apiExecutor);

  return function* newsSaga(): GeneratorType {
    yield takeLatest(FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START, fetchAllMatchesForMatchOfTheWeek);
    yield takeLatest(FETCH_MATCHRESULT_START, fetchMatchResult);
  };

  function* fetchMatchResult(action: FETCH_MATCHRESULT_START_ACTION): Saga<void> {
    try {
      const { match_semaine } = action.payload;

      // si on a le match de la semaine, on fait l'appel à l'agrégateur
      const matchResult = yield call(matchResultApi.fetchMatchResultOfTheWeek, match_semaine);

      yield put(matchResultFetched(matchResult));
    } catch (e) {
      yield put(matchResultFetchError());
    }
  }

  function* fetchAllMatchesForMatchOfTheWeek(action: FETCH_ALL_MATCHES_FOR_MATCH_OF_THE_WEEK_START_ACTION): Saga<void> {
    try {
      const { structure, dateMinusAWeek, datePlusAWeek } = action.payload;

      // si on a les éléments, on fait l'appel à l'agrégateur
      const allMatchesResults = yield call(
        matchResultApi.fetchAllMatchesForMatchOfTheWeek,
        structure,
        dateMinusAWeek,
        datePlusAWeek
      );

      yield put(allMatchesForMatchOfTheWeekFetched(allMatchesResults));
    } catch (e) {
      yield put(allMatchesForMatchOfTheWeekFetchError());
    }
  }
}
