// @flow

import { mergeIntoDefault } from 'utils/objectUtils';

import { type RawAlbumType, type AlbumType, nullAlbum } from 'types/Albums';

export const convertAlbumFromApi = (rawAlbum: RawAlbumType) => {
  if (!rawAlbum) {
    return nullAlbum;
  }
  const {
    id,
    title: { rendered: title },
    content,
    excerpt,
    date: rawDate,
    meta_title,
    meta_description,
    featured_media,
    breadcrumb,
    parent_link
  } = rawAlbum;

  const date = new Date(rawDate);

  return mergeIntoDefault(nullAlbum, {
    id,
    title,
    content,
    excerpt,
    date,
    meta_title,
    meta_description,
    featured_media,
    breadcrumb,
    parent_link
  });
};

export const convertAlbumsFromApi = (rawAlbums: RawAlbumType[]): AlbumType[] => {
  if (!rawAlbums) {
    return [nullAlbum];
  }

  return rawAlbums.map((rawAlbum): AlbumType => {
    const {
      id,
      slug,
      title: { rendered: title },
      content,
      content: { data },
      excerpt,
      date: rawDate,
      featured_media: { src, sizes },
      filtres_albums,
      saison
    } = rawAlbum;

    const date = new Date(rawDate);

    let itemsCount = 0;

    const galleryalbum = data.filter(({ tagName }) => tagName === 'galleryalbum');

    if (!!galleryalbum && !!galleryalbum.length) {
      const secondGalleryAlbum = !!galleryalbum.length && galleryalbum[0].children;

      if (!!secondGalleryAlbum && !!secondGalleryAlbum.length && secondGalleryAlbum[0].tagName === 'galleryalbum') {
        const secondGalleryAlbumChildren = secondGalleryAlbum[0].children;
        itemsCount =
          !!secondGalleryAlbumChildren && secondGalleryAlbumChildren.length && secondGalleryAlbumChildren.length;
      } else {
        const items = galleryalbum[0].children && galleryalbum[0].children.length && galleryalbum[0].children;
        itemsCount = items && items.filter(item => item.tagName !== 'figcaption').length;
      }
    }

    return mergeIntoDefault(nullAlbum, {
      id,
      slug,
      title,
      content,
      excerpt,
      date,
      itemsCount,
      featured_media: {
        src,
        sizes
      },
      filtres_albums,
      saison
    });
  });
};
