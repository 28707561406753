// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import EventApi from 'api/events/EventApi';

import { FETCH_EVENT_START, eventFetched, eventFetchError } from 'actions/eventActions';
import type { FETCH_EVENT_START_ACTION } from 'actions/eventActions';
import {
  FETCH_EVENTS_PREVIEW_START,
  eventsPreviewFetched,
  eventsPreviewFetchError
} from 'actions/eventsPreviewActions';
import type { FETCH_EVENTS_PREVIEW_START_ACTION } from 'actions/eventsPreviewActions';
import { FETCH_EVENTS_START, eventsFetched, eventsFetchError } from 'actions/eventsActions';
import type { FETCH_EVENTS_START_ACTION } from 'actions/eventsActions';

export default function(apiExecutor: ApiExecutorType) {
  const eventsApi = new EventApi(apiExecutor);

  return function* eventsSaga(): GeneratorType {
    yield takeLatest(FETCH_EVENT_START, fetchEvent);
    yield takeLatest(FETCH_EVENTS_PREVIEW_START, fetchEventsPreview);
    yield takeLatest(FETCH_EVENTS_START, fetchEvents);
  };

  function* fetchEventsPreview(action: FETCH_EVENTS_PREVIEW_START_ACTION): Saga<void> {
    try {
      const events = yield call(eventsApi.fetchEventsPreview);
      yield put(eventsPreviewFetched(events));
    } catch (e) {
      yield put(eventsPreviewFetchError());
    }
  }

  function* fetchEvents(action: FETCH_EVENTS_START_ACTION): Saga<void> {
    try {
      const { from, to } = action.payload;
      const events = yield call(eventsApi.fetchEvents, from, to);
      yield put(eventsFetched(events));
    } catch (e) {
      yield put(eventsFetchError());
    }
  }

  function* fetchEvent(action: FETCH_EVENT_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const events = yield call(eventsApi.fetchEvent, slug);
      yield put(eventFetched(events, true));
    } catch (e) {
      yield put(eventFetchError());
    }
  }
}
