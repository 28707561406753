
// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SourceSetType } from 'types/Article';

export type FilArianneItemType = {
  name: string,
  to: string,
}

export type RawPartenaireType = {
  id: number,
  date: Date,
  modified: string,
  slug: string,
  status: string,
  type: string,
  link: string,
  title: {
    rendered: string,
  },
  content: {
    data: BlocContent[],
  },
  excerpt: {
    data: BlocContent[],
  },
  featured_media: {
    src: string,
    sizes: SourceSetType
  },
  meta: {
    lien: string,
    lien_cta: string,
    facebook: string,
    twitter: string,
    youtube: string,
    instagram: string,
    linkedin: string,
  },
  sidebar_content: {
    data: Array<BlocContent>
  },
  meta_title: string,
  meta_description: string,
  niveau: number,
  filarianne: FilArianneItemType[],
};

export type PartenaireType = {
  id: number,
  title: string,
  slug: string,
  content: {
    data: BlocContent[]
  },
  excerpt: {
    data: BlocContent[],
  },
  featured_media: {
    src: string,
    sizes: SourceSetType,
  },
  lien: string,
  lien_cta: string,
  facebook: string,
  twitter: string,
  youtube: string,
  instagram: string,
  linkedin: string,
  sidebar: Array<BlocContent>,
  meta_title: string,
  meta_description: string,
  filarianne: FilArianneItemType[]
};

export const nullPartenaire: PartenaireType = {
  id: 0,
  title: '',
  slug: '',
  content: {
    data: [],
  },
  excerpt: {
    data: [],
  },
  featured_media: {
    src: '',
    sizes: {},
  },
  lien: '',
  lien_cta: '',
  facebook: '',
  twitter: '',
  youtube: '',
  instagram: '',
  linkedin: '',
  sidebar: [],
  meta_title: '',
  meta_description: '',
  filarianne: []
};
