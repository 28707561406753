// @flow
import App from 'components/app/App';
import { connect } from 'react-redux';
import {
  fetchSettings,
  increaseHightlighterPostModalCount,
  setShowHighlightedPostModal,
  resetHightlighterPostModalCount
} from 'actions/appActions';
import { fetchMenu } from 'actions/menuActions';
import { displayModal } from 'actions/modalActions';
import { fetchPartners } from 'actions/partnerActions';
import { fetchCategories } from 'actions/categoriesActions';
import { keycloakConnect } from 'actions/userActions';

import type { StateProps, DispatchProps } from 'components/app/App';
import type { StateType, DispatchType } from 'types/Actions';
import type { HighLightedPostType } from 'types/Settings';
import { withRouter } from 'react-router-dom';

export default withRouter(
  connect(
    (state: StateType): StateProps => ({
      favicons: state.settingsState.favicons,
      highlighted_post: state.settingsState.highlighted_post,
      highlightedPostModalCount: state.appState.highlightedPostModalCount,
      showHighlightedPostModal: state.appState.showHighlightedPostModal,
      titleLigue: state.settingsState.title
    }),
    (dispatch: DispatchType): DispatchProps => ({
      displayModal: (modalObject: string, content?: Object, force?: boolean) =>
        dispatch(displayModal(modalObject, content, force)),
      fetchSettings: () => dispatch(fetchSettings()),
      fetchMenu: () => dispatch(fetchMenu()),
      fetchCategories: () => dispatch(fetchCategories()),
      fetchPartners: () => dispatch(fetchPartners()),
      increaseHightlighterPostModalCount: () => dispatch(increaseHightlighterPostModalCount()),
      keycloakConnect: () => dispatch(keycloakConnect()),
      setShowHighlightedPostModal: (hithlightedPost: HighLightedPostType) =>
        dispatch(setShowHighlightedPostModal(hithlightedPost)),
      resetHightlighterPostModalCount: () => dispatch(resetHightlighterPostModalCount())
    })
  )(App)
);
