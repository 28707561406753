/// @flow
import React, { Fragment, PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import ErrorMessage from 'components/fragments/ErrorMessage';

type Props = {
  placeholder: string,
  options: Array<{ label: string, value: string }>,
  isDark?: boolean,
  isLight?: boolean,
  onChange?: (value: string) => void,
  hasError?: boolean,
  errorMessage?: string,
  defaultValue?: string | null,
  value?: string
};

type State = {
  isTouched: boolean
};

class SelectInput extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    isTouched: false
  };

  static defaultProps = {
    placeholder: 'placeholder',
    options: [
      {
        value: 'option1',
        label: 'option1'
      }
    ],
    defaultValue: 'default'
  };

  handleValueChanged = (e: any) => {
    const value = e.target.value;
    if (!this.state.isTouched) this.setState({ isTouched: true });
    if (this.props.onChange) this.props.onChange(value);
  };

  render() {
    const { defaultValue, isDark, hasError, isLight, value } = this.props;
    const classNames = dynamicClassName('select');
    isDark && classNames.add('is-dark');
    isLight && classNames.add('select--light');
    hasError && classNames.add('has-error');
    this.state.isTouched && classNames.add('is-selected');

    const defaultVal = defaultValue ?? 'default';

    return (
      <Fragment>
        <div className={classNames.build()}>
          <select value={value ?? defaultVal} onChange={this.handleValueChanged}>
            <option disabled value={defaultVal}>
              {this.props.placeholder}
            </option>
            {this.renderOptions(this.props.options)}
          </select>
        </div>
        {hasError && this.props.errorMessage !== undefined && <ErrorMessage message={this.props.errorMessage} />}
      </Fragment>
    );
  }

  renderOptions = (options: any) => {
    return options.map((option, index) => (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    ));
  };
}

export default SelectInput;
