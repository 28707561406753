export const TYPE_TERRITORIAL = 'territoriale';
export const TYPE_FEDERAL = 'fédérale';
export const FAMILLE_TEXT = [
  { type: 'fédérale', text: 'Fédérales' },
  { type: 'federale', text: 'Fédérales' },
  { type: 'autre', text: 'Territoriales' },
  { type: 'territoriale', text: 'Territoriales' }
];
export const AGE_TEXT = [
  { type: '-19', text: 'Juniors' },
  { type: '-18', text: 'Juniors' },
  { type: '+18', text: 'Seniors' },
  { type: '-16', text: 'Cadets' }
];
export const SEXE_TEXT = [
  { type: 'F', text: ' Féminines' },
  { type: 'M', text: '' }
];
export const FFR_STRUCTURE_ORGANISATRICE_ID = 1837;