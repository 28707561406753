// @flow
import type { RawEventType, EventPreviewType, EventType } from 'types/Event';
import { mergeIntoDefault, convertToValidDateFormat } from 'utils/objectUtils';
import { getEventDate } from 'utils/eventUtils';
import { nullEvent, nullEventPreview } from 'types/Event';

export function convertEventPreviewFromApi(rawEvent: RawEventType): EventPreviewType {
  if (!rawEvent) {
    return nullEventPreview;
  }

  const {
    start_date,
    end_date,
    title,
    slug,
    venue: { venue, address, city, zip },
    import_type,
    redirection,
    categories
  } = rawEvent;

  return mergeIntoDefault(nullEventPreview, {
    startDate: new Date(convertToValidDateFormat(start_date)),
    endDate: new Date(convertToValidDateFormat(end_date)),
    title: title.raw,
    label: rawEvent.categories[0] ? rawEvent.categories[0].name : '',
    slug,
    venue,
    address,
    city,
    zip,
    import_type,
    redirection,
    categories
  });
}

export function convertEventFromApi(rawEvent: RawEventType): EventType {
  if (!rawEvent) {
    return nullEvent;
  }
  const {
    utc_start_date_details,
    utc_end_date_details,
    start_date,
    end_date,
    title,
    slug,
    image,
    description: { rendered },
    venue: { venue, address, city, zip, geo_lat, geo_lng },
    all_day,
    organizer,
    import_type,
    redirection,
    categories
  } = rawEvent;

  return mergeIntoDefault(nullEvent, {
    startDateDetail: getEventDate(utc_start_date_details),
    endDateDetail: getEventDate(utc_end_date_details),
    startDate: new Date(convertToValidDateFormat(start_date)),
    endDate: new Date(convertToValidDateFormat(end_date)),
    title: title.raw,
    label: rawEvent.categories && rawEvent.categories.length > 0 ? rawEvent.categories[0].name : '',
    slug,
    image: image !== false ? image.sizes.thumbnail : '',
    mediumImage:
      image !== false && !!image.sizes && !!image.sizes.medium && image.sizes.medium.url ? image.sizes.medium.url : '',
    description: rendered,
    venue,
    adress: address,
    zip,
    city,
    organizer: organizer.length > 0 && organizer[0].organizer,
    phone: organizer.length > 0 && organizer[0].phone,
    email: organizer.length > 0 && organizer[0].email,
    all_day,
    lat: geo_lat,
    long: geo_lng,
    import_type,
    redirection,
    categories
  });
}
