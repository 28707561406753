// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import DidomiCookie from 'components/Didomi/DidomiCookieConsent';
import FooterSelect from 'components/footer/FooterSelect';
import type { MenuElementType } from 'types/MenuElement';

import './FooterBottomStyle.css';

type Props = {
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  footerElements: MenuElementType[],
  logo_footer: string,
  title: string
};

class FooterBottom extends PureComponent<Props> {
  static defaultProps = {
    footerElements: []
  };

  renderFooterCategory = () => {
    const { footerElements } = this.props;

    const categories = [];
    let categoriesCount = 0;
    footerElements.forEach((footerCategory, index) => {
      categories[categoriesCount] = categories[categoriesCount] || [];
      categories[categoriesCount].push(<FooterSelect key={index} footerCategory={footerCategory} />);
      if ((index + 1) % 4 === 0) {
        // the 4 here is how many items you want per row
        // if we can perfectly divide the current position by 4
        // we have filled a row, so now increment the row
        categoriesCount++;
      }
    });
    return categories;
  };

  render() {
    const {
      contacts: { adresse, telephone },
      logo_footer,
      title
    } = this.props;

    const capitalizedAddress = `${
      adresse
        ? adresse
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : ''
    }`;
    const splitedAddress = capitalizedAddress.split('<br />');
    const internationalPhoneNumber =
      telephone && telephone.slice(0, 1) === '0' ? `+33 ${telephone.substr(1)}` : telephone;

    return (
      <>
        <div className="container footer__links">
          <div className="footer__contact-detail">
            <div className="footer__address">
              {adresse && (
                <>
                  <i className="icon icon-place"></i>
                  <div className="footer__address-detail">
                    {adresse && splitedAddress.map(addressPart => <p key={addressPart}>{addressPart}</p>)}
                  </div>
                </>
              )}
            </div>
            {internationalPhoneNumber && (
              <div className="footer__phone-number">
                <i className="icon icon-phone"></i>
                <p>{internationalPhoneNumber}</p>
              </div>
            )}
          </div>
          <div className="row">
            {this.renderFooterCategory()}
            <div className="col col-lg-3 footer__logo mt-3 mt-lg-0">
              <img
                className="ligue"
                src={logo_footer}
                alt={`Logo officiel de la ${title} de Rugby`}
                style={{ filter: 'grayscale(100%)' }}
              />
            </div>
          </div>
        </div>
        <div className="footer__corporate">
          <div className="container">
            <div className="row">
              <div className="col col-lg-8">
                <ul className="footer__corporate-links">
                  <li>
                    <Link to="/contact" title={`Contacter la ${title} de Rugby`}>
                      Nous contacter
                    </Link>
                  </li>
                  <li>
                    <a
                      href={
                        window.location.origin.includes('ffr.fr')
                          ? 'https://www.ffr.fr/cgu'
                          : 'https://pp.website.ffr.nextmap.io/cgu'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      title={`Consulter les Conditions générales d’utilisation de la Fédération Française de Rugby`}
                    >
                      Conditions générales d’utilisation
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        window.location.origin.includes('ffr.fr')
                          ? 'https://www.ffr.fr/donnees-personnelles'
                          : 'https://pp.website.ffr.nextmap.io/donnees-personnelles'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      title={`Consulter la politique de données persoonnelles de la Fédération Française de Rugby`}
                    >
                      Données personnelles
                    </a>
                  </li>
                  <li>
                    <Link to="/faq" title={`Consulter la Foire Aux Questions de la ${title} de Rugby`}>
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="/mentions-legales" title={`Consulter les mentions légales de la ${title} de Rugby`}>
                      Mentions légales
                    </Link>
                  </li>
                  <li>
                    <DidomiCookie />
                  </li>
                </ul>
              </div>
              <div className="col col-lg-4">
                {/* TBD Il faudrait dynamiser l'année pour faire 2018-20XX dès l'année prochaine */}
                <p className="footer__corporate-legal">
                  © 2018-{new Date().getFullYear()} {title} de Rugby
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FooterBottom;
