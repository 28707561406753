// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

type Props = {
  onSetOverlay?: (overlay: boolean) => void,
  mobile?: boolean,
  history: any,
  onCloseMenu?: Function
};

type ComponentState = {
  focused: boolean,
  value: string
};

class Search extends PureComponent<Props, ComponentState> {
  _node: any;
  static defaultProps = {
    mobile: false
  };
  state: ComponentState = {
    focused: false,
    value: ''
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  setFocused(focused: boolean) {
    this.setState({ focused });
    if (this.props.onSetOverlay) this.props.onSetOverlay(focused);
  }

  onFocus = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'recherche classique',
        eventAction: 'clic recherche',
        eventLabel: window.location.pathname
      },
      dataLayerName: 'dataLayer'
    });

    this.setFocused(true);
  };

  handleClick = (e: MouseEvent) => {
    if (!this._node.contains(e.target)) this.setFocused(false);
  };

  handleSubmit = (e: Event) => {
    e.preventDefault();

    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'recherche classique',
        eventAction: 'recherche',
        eventLabel: this.state.value
      },
      dataLayerName: 'dataLayer'
    });

    this.props.history.replace(`/recherche?s=${this.state.value}`);
    this.setFocused(false);
    this.setState({ value: '' });

    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  };

  handleChange = (e: Event) => {
    if (e.target instanceof HTMLInputElement) {
      this.setState({ value: e.target.value });
    }
  };

  render() {
    const { mobile } = this.props;
    const { focused } = this.state;
    let formLabel;

    const classNamesInput = dynamicClassName('input input--search input--expand');
    focused && classNamesInput.add('is-open has-overlay');

    if (mobile) {
      formLabel = 'form_recherche_mobile';
    } else {
      formLabel = 'form_recherche';
    }

    return (
      <form ref={node => (this._node = node)} onSubmit={this.handleSubmit}>
        <div className={classNamesInput.build()} data-overlay="false">
          <input
            type="search"
            id={formLabel}
            title="Effectuer une recherche sur le site"
            tabIndex={0}
            placeholder="Rechercher"
            onFocus={this.onFocus}
            value={this.state.value}
            onChange={this.handleChange}
          />
          <label htmlFor={formLabel}>Rechercher</label>
        </div>
      </form>
    );
  }
}

export default withRouter(Search);
