// @flow

const key = '__app';

type WindowAppType = {
  placeholder: string,
};

const nullWindowApp: WindowAppType = {
  placeholder: '',
};

export function initWindowApp() {
  window[key] = window[key] || nullWindowApp;
}

export function getWindowApp(subkey: ?string) {
  try {
    if (subkey) {
      return window[key][subkey];
    }

    return window[key];
  } catch (e) {}
  return false;
}

export function setWindowApp(subkey: string, value: any): void {
  try {
    window[key][subkey] = value;
  } catch (e) {}
}
