export const baseMenuItemWidth = 45;
export const baseHeaderCategorySearchWidth = 58;
export const letterWidth = 5;
export const letterWidthSearch = 9;
export const logoWidth = 207;
export const footerBlacklistPaths = [
    '/trouver-un-club-de-rugby/resultats-de-recherche',
    '/trouver-un-club-de-rugby/resultats-de-recherche/'
];
export const headerFilterClubWithlistPaths = [
    '/trouver-un-club-de-rugby/resultats-de-recherche',
    '/trouver-un-club-de-rugby/resultats-de-recherche/'
];
export const topBarMenuApiName = 'top-nav';
export const headerMenuApiName = 'header';
export const footerMenuApiName = 'footer';
