/// @flow
import React, { Fragment, PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import ErrorMessage from 'components/fragments/ErrorMessage';

type Props = {
  id: string,
  placeholder: string,
  errorMessage?: string,
  hasError?: boolean,
  isDark?: boolean,
  isLight?: boolean,
  onChange?: (value: string) => void,
  isFull?: boolean
};

class TextInput extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    id: 'TextInput',
    placeholder: 'PlaceHolder'
  };

  handleInputChanged = (e: any) => {
    if (this.props.onChange) this.props.onChange(e.target.value);
  };

  render() {
    const { isDark, isFull, isLight, hasError } = this.props;
    const classNames = dynamicClassName('input');
    isDark && classNames.add('is-dark');
    isLight && classNames.add('input--light');
    isFull && classNames.add('is-full');
    hasError && classNames.add('has-error');
    return (
      <Fragment>
        <div className={classNames.build()}>
          <input
            type="text"
            id={this.props.id}
            placeholder={this.props.placeholder}
            onChange={this.handleInputChanged}
          />
          <label htmlFor={this.props.id}>{this.props.placeholder}</label>
          {hasError && <i className="icon icon-info" />}
        </div>
        {hasError && this.props.errorMessage && <ErrorMessage message={this.props.errorMessage} />}
      </Fragment>
    );
  }
}

export default TextInput;
