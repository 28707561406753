/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
  id: string,
  label: string,
  isDark?: boolean,
  onChange?: (checked: boolean) => void,
  isFull?: boolean
};

class CheckboxInput extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    id: 'checkboxinput123',
    label:
      'J’accepte les emails de la Ligue Franche comté de rugby à propos des nouveautés et promotions de la Ligue. ' +
      ' Je peux me désinscrire à tout moment en utilisant le lien pour me désabonner au pied de la newsletter.'
  };

  handleCheckboxCliked = (e: any) => {
    if (this.props.onChange) this.props.onChange(e.target.checked);
  };

  render() {
    const { isDark, isFull } = this.props;
    const classNames = dynamicClassName('checkbox col col-lg-4');
    isFull && classNames.remove('col-lg-4') && classNames.add('col-md-8');
    isDark && classNames.add('is-dark');
    return (
      <div className={classNames.build()}>
        <input type="checkbox" id={this.props.id} onClick={this.handleCheckboxCliked} />
        <label htmlFor={this.props.id}>{this.props.label}</label>
      </div>
    );
  }
}

export default CheckboxInput;
