// @flow
import React from 'react';

import CardThumbnail from 'components/fragments/card/CardThumbnail';

type Props = {
  content: any,
  hideModal: () => void
};

function HighlightedPostModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal__container col-md-6 col-lg-4">
      <div className="modal-content box highlighted-post-modal">
        <i role="button" tabIndex={0} className="icon icon-close js-closeModal" onClick={hideModal}></i>
        <CardThumbnail
          url={content.more_info_link}
          backgroundUrl={{src: content.image}}
          title={content.title}
          desc={content.excerpt}
          onClick={hideModal}
        />
      </div>
    </div>
  );
}

export default HighlightedPostModalContent;
