// @flow
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import Search from 'components/header/Search';
import TopBarMobile from 'components/header/TopBarMobile';
import { dynamicClassName } from 'utils/dynamicClassName';
import { handleTagEvent } from 'utils/tagManagerUtils';
import type { RouterProps } from 'types/Router';

type StateProps = {
  logo: string
};

type Props = RouterProps & {
  onToggleMenu: Function,
  isMenuOpen: boolean,
  onCloseMenu: Function
} & StateProps;

type ComponentState = {
  hasOverlay: boolean
};

class HeaderMobile extends PureComponent<Props, ComponentState> {
  state: ComponentState = {
    hasOverlay: false
  };

  setOverlay = (hasOverlay: boolean) => {
    this.setState({ hasOverlay });
  };

  render() {
    const { onCloseMenu, isMenuOpen, logo } = this.props;
    const { hasOverlay } = this.state;
    const classNamesIconShowMenu = dynamicClassName('icon icon-menu js-showMenu');
    const classNamesOverlay = dynamicClassName('overlay');
    isMenuOpen && classNamesIconShowMenu.add('is-open');
    hasOverlay && classNamesOverlay.add('is-visible');

    return (
      <React.Fragment>
        <TopBarMobile />

        <div className="header__mobile">
          <Link title="Se rendre sur la page d'accueil" to="/" onClick={onCloseMenu}>
            <img src={logo} alt="logo" />
          </Link>

          <div className="header__mobile__right">
            <Search mobile={true} onSetOverlay={this.setOverlay} onCloseMenu={onCloseMenu} />

            <Link
              to="/trouver-un-club-de-rugby"
              title="Se rendre sur la page de recherche d'un club"
              className=""
              onClick={handleTagEvent(
                'recherche geolocalisee',
                'clic trouver un club',
                this.props.location.pathname,
                onCloseMenu
              )}
            >
              <i className="icon icon-place is-inline" />
            </Link>

            <i
              className={classNamesIconShowMenu.build()}
              role="button"
              tabIndex={0}
              onClick={this.props.onToggleMenu}
            />
          </div>
        </div>

        <div className={classNamesOverlay.build()} onClick={onCloseMenu} />
      </React.Fragment>
    );
  }
}

export default withRouter(HeaderMobile);
