// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { SocialContent } from 'types/SocialContent';
import { convertInstagramPostsFromApi } from 'services/Social';

function InstagramApi(executor: ApiExecutorType) {
  return { fetchPosts };

  function fetchPosts(): Promise<Array<SocialContent>> {
    return executor.get(`ffr/v1/rs_instagram`) 
            .then(responses => {
              if (responses.data) {
                return responses.data.map(resp => convertInstagramPostsFromApi(resp));
              }
              return [];
            });
  }
}

export default InstagramApi;
