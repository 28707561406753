// @flow
import TopBar from 'components/header/TopBar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import type { StateType } from 'types/Actions';

export default withRouter(connect((state: StateType, ...ownProps: any) => ({
  connectionInProgress: state.userState.connectionInProgress,
  keycloakData: state.userState.keycloakData,
  topBarElements: state.menuState.topBar,
  topNews: state.settingsState.top_news 
}))(TopBar));
