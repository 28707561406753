// @flow
import type { BlocContent } from 'types/NewsletterBloc';

export type liensRsType = {
  fb_lien: string,
  instagram_lien: string,
  twitter_lien: string,
  youtube_lien: string,
  flickr_lien: string,
  linkedin_lien: string,
  twitch_lien: string,
  snapchat_lien: string,
  tik_tok_lien: string
};

export type FaviconType = {
  'android-192': string,
  'android-256': string,
  'apple-touch-icon-152': string,
  'apple-touch-icon-180': string,
  'favicon-16': string,
  'favicon-32': string,
  'mstile-150': string
};

export type ListHomeCardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  parent_slug: string,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type HomeCardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  parent_slugs: Object,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BlocFaq = {
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  description: string,
  featured_image: Object,
  parent_slugs: Array<string>,
  texte_cta: string
};

export type PartnerType = {
  cta: string,
  description: string,
  facebook: string,
  instagram: string,
  lien: string,
  lien_type: string,
  logo: string,
  titre: string,
  twitter: string,
  youtube: string
};

export type HighLightedPostType = {
  title: string,
  image: string,
  excerpt: string,
  more_info_link: string
};

export type SettingsType = {
  ligue: string,
  ligue_id: number,
  title: string,
  description: string,
  url: string,
  logo: string,
  logo_footer: string,
  top_news: {
    title: string,
    link: string,
    label: string
  },
  liste_cards: ListHomeCardType[],
  class_ligue: string,
  titre_liste_cards: string,
  titre_liste_blocs_un: string,
  gerer_club: HomeCardType,
  bulletin_officiel: HomeCardType,
  match_semaine: number,
  blocs_faq: Array<BlocFaq>,
  blocs_trouver_club: Array<BlocFaq>,
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: FaviconType,
  liens_rs: liensRsType,
  newsletter: {
    type: false | 'campaign_monitor' | 'lien' | 'mailchimp',
    lien?: string,
    api_key?: string
  },
  cookies: number,
  partenaires: PartnerType[],
  partenaires_page: string,
  partenaires_une_titre: string,
  partenaires_une_description: string,
  message_alerte: {
    raw: string,
    data: BlocContent[]
  },
  home: {
    title: string,
    content: BlocContent[]
  },
  actualite_ffr_vers_ligues: {
    featured_media: Object,
    link: string,
    title: {
      rendered: string,
      raw: string
    },
    date: string,
    label: {
      name: string,
      slug: string
    }
  },
  highlighted_post: HighLightedPostType
};

export type RawSettingsType = {
  ligue: string,
  ligue_id: string,
  title: string,
  description: string,
  url: string,
  email: string,
  timezone: string,
  date_format: string,
  time_format: string,
  start_of_week: number,
  language: string,
  use_smilies: boolean,
  default_category: number,
  default_post_format: string,
  posts_per_page: number,
  default_ping_status: string,
  default_comment_status: string,
  logo: string,
  logo_footer: string,
  top_news: {
    title: string,
    link: string,
    label: string
  },
  class_ligue: string,
  titre_liste_cards: string,
  titre_liste_blocs_un: string,
  liste_cards: Array<HomeCardType>,
  'gerer-club': HomeCardType,
  'bulletin-officiel': HomeCardType,
  liste_blocs_un: {
    gros_bloc: HomeCardType,
    petit_bloc: HomeCardType
  },
  match_semaine: number,
  blocs_faq: Array<BlocFaq>,
  blocs_trouver_club: Array<BlocFaq>,
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: FaviconType,
  liens_rs: liensRsType,
  newsletter: {
    type: false | 'campaign_monitor' | 'lien' | 'mailchimp',
    lien?: string,
    api_key?: string
  },
  cookies: number,
  message_alerte: { raw: string, data: Array<BlocContent> },
  home: {
    title: string,
    content: Array<BlocContent>
  },
  actualite_ffr_vers_ligues: {
    featured_media: Object,
    link: string,
    title: {
      rendered: string,
      raw: string
    },
    date: string,
    label: {
      name: string,
      slug: string
    }
  },
  partenaires: PartnerType[],
  partenaires_page: string,
  partenaires_une_titre: string,
  partenaires_une_description: string,
  highlighted_post: HighLightedPostType
};

export const nullPartner: PartnerType = {
  cta: '',
  description: '',
  facebook: '',
  instagram: '',
  lien: '',
  lien_type: '',
  logo: '',
  titre: '',
  twitter: '',
  youtube: ''
};

export const nullHighlightedPost = {
  title: '',
  image: '',
  excerpt: '',
  more_info_link: ''
};

export const nullSettings: SettingsType = {
  ligue: '',
  ligue_id: 0,
  title: '',
  description: '',
  url: '',
  logo: '',
  logo_footer: '',
  top_news: {
    title: '',
    link: '',
    label: ''
  },
  liste_cards: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      parent_slug: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    }
  ],
  class_ligue: '',
  titre_liste_cards: '',
  titre_liste_blocs_un: '',
  gerer_club: {
    object_id: 0,
    type_name: '',
    object_slug: '',
    title: '',
    description: '',
    parent_slugs: {},
    featured_image: {},
    texte_cta: ''
  },
  bulletin_officiel: {
    object_id: 0,
    type_name: '',
    object_slug: '',
    title: '',
    description: '',
    parent_slugs: {
      '1': '',
      '2': ''
    },
    featured_image: {},
    texte_cta: ''
  },
  match_semaine: 0,
  blocs_faq: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  blocs_trouver_club: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  contacts: {
    adresse: '',
    adresse_map: '',
    telephone: '',
    email: '',
    horaires: ''
  },
  favicons: {
    'android-192': '',
    'android-256': '',
    'apple-touch-icon-152': '',
    'apple-touch-icon-180': '',
    'favicon-16': '',
    'favicon-32': '',
    'mstile-150': ''
  },
  liens_rs: {
    fb_lien: '',
    instagram_lien: '',
    twitter_lien: '',
    youtube_lien: '',
    flickr_lien: '',
    linkedin_lien: '',
    twitch_lien: '',
    snapchat_lien: '',
    tik_tok_lien: ''
  },
  newsletter: { type: false },
  cookies: 0,
  message_alerte: {
    raw: '',
    data: []
  },
  home: {
    title: '',
    content: []
  },
  actualite_ffr_vers_ligues: {
    featured_media: {},
    link: '',
    title: {
      rendered: '',
      raw: ''
    },
    date: '',
    label: {
      name: '',
      slug: ''
    }
  },
  partenaires: [nullPartner],
  partenaires_page: '',
  partenaires_une_titre: '',
  partenaires_une_description: '',
  highlighted_post: nullHighlightedPost
};
