// @flow

import type { ContactFormType, InputValue, SingleContactFormType } from 'types/ContactForm7';

// =============================================================================================
// fetchFirstCF7 form
export const FETCH_CF7_CONTACT_FORM_START = 'FETCH_CF7_CONTACT_FORM_START';
export const FETCH_CF7_CONTACT_FORM_SUCCESS = 'FETCH_CF7_CONTACT_FORM_SUCCESS';
export const FETCH_CF7_CONTACT_FORM_FAILURE = 'FETCH_CF7_CONTACT_FORM_FAILURE';

export type FETCH_CONTACT_FORM_7_START_ACTION = {
  type: 'FETCH_CF7_CONTACT_FORM_START'
};

export type FETCH_CONTACT_FORM_7_SUCCESS_ACTION = {
  type: 'FETCH_CF7_CONTACT_FORM_SUCCESS',
  payload: {
    contactForm: ContactFormType
  }
};

export type FETCH_CONTACT_FORM_7_FAILURE_ACTION = {
  type: 'FETCH_CF7_CONTACT_FORM_FAILURE'
};

export function fetchCF7ContactForm(): FETCH_CONTACT_FORM_7_START_ACTION {
  // console.log('CF7actions - fetchCF7ContactForm');
  return {
    type: FETCH_CF7_CONTACT_FORM_START
  };
}

export function cf7FormFetched(contactForm: ContactFormType): FETCH_CONTACT_FORM_7_SUCCESS_ACTION {
  // console.log('CF7actions - cf7FormFetched');
  return {
    type: FETCH_CF7_CONTACT_FORM_SUCCESS,
    payload: { contactForm }
  };
}

export function cf7FormFetchedError(error: any): FETCH_CONTACT_FORM_7_FAILURE_ACTION {
  // console.log('CF7actions - cf7FormFetchedError');
  return {
    type: FETCH_CF7_CONTACT_FORM_FAILURE
  };
}

// =============================================================================================
// fetch la liste des formulaires
export const FETCH_ALL_CF7_CONTACT_FORM_START = 'FETCH_ALL_CF7_CONTACT_FORM_START';
export const FETCH_ALL_CF7_CONTACT_FORM_SUCCESS = 'FETCH_ALL_CF7_CONTACT_FORM_SUCCESS';
export const FETCH_ALL_CF7_CONTACT_FORM_FAILURE = 'FETCH_ALL_CF7_CONTACT_FORM_FAILURE';

export type FETCH_ALL_CF7_CONTACT_FORM_START_ACTION = {
  type: 'FETCH_ALL_CF7_CONTACT_FORM_START'
};

export type FETCH_ALL_CF7_CONTACT_FORM_SUCCESS_ACTION = {
  type: 'FETCH_ALL_CF7_CONTACT_FORM_SUCCESS',
  payload: {
    contactFormList: Array<ContactFormType>
  }
};

export type FETCH_ALL_CF7_CONTACT_FORM_FAILURE_ACTION = {
  type: 'FETCH_ALL_CF7_CONTACT_FORM_FAILURE'
};

export function fetchAllContactForm7Forms(): FETCH_ALL_CF7_CONTACT_FORM_START_ACTION {
  // console.log('CF7actions - fetchAllContactForm7Forms');
  return {
    type: FETCH_ALL_CF7_CONTACT_FORM_START
  };
}

export function cf7FormListFetched(contactFormList: Array<ContactFormType>): FETCH_ALL_CF7_CONTACT_FORM_SUCCESS_ACTION {
  // console.log('CF7actions - cf7FormListFetched');
  return {
    type: FETCH_ALL_CF7_CONTACT_FORM_SUCCESS,
    payload: { contactFormList }
  };
}

export function cf7FormListFetchedError(error: any): FETCH_ALL_CF7_CONTACT_FORM_FAILURE_ACTION {
  // console.log('CF7actions - cf7FormListFetchedError');
  return {
    type: FETCH_ALL_CF7_CONTACT_FORM_FAILURE
  };
}

// =============================================================================================
// fetch la liste des formulaires
export const FETCH_SINGLE_CF7_CONTACT_FORM_START = 'FETCH_SINGLE_CF7_CONTACT_FORM_START';
export const FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS = 'FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS';
export const FETCH_SINGLE_CF7_CONTACT_FORM_FAILURE = 'FETCH_SINGLE_CF7_CONTACT_FORM_FAILURE';

export type FETCH_SINGLE_CONTACT_FORM_7_START_ACTION = {
  type: 'FETCH_SINGLE_CF7_CONTACT_FORM_START',
  payload: {
    id: number
  }
};

export type FETCH_SINGLE_CONTACT_FORM_7_SUCCESS_ACTION = {
  type: 'FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS',
  payload: {
    singleContactForm: SingleContactFormType
  }
};

export type FETCH_SINGLE_CF7_CONTACT_FORM_FAILURE_ACTION = {
  type: 'FETCH_SINGLE_CF7_CONTACT_FORM_FAILURE'
};

export function fetchSingleCF7FormContact(id: number): FETCH_SINGLE_CONTACT_FORM_7_START_ACTION {
  // console.log('CF7actions - fetchSingleCF7FormContact');
  return {
    type: FETCH_SINGLE_CF7_CONTACT_FORM_START,
    payload: {
      id
    }
  };
}

export function singlecf7FormFetched(
  singleContactForm: SingleContactFormType
): FETCH_SINGLE_CONTACT_FORM_7_SUCCESS_ACTION {
  // console.log('CF7actions - singlecf7FormFetched');
  return {
    type: FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS,
    payload: { singleContactForm }
  };
}

export function singlecf7FormFetchedError(error: any): FETCH_SINGLE_CF7_CONTACT_FORM_FAILURE_ACTION {
  // console.log('CF7actions - singlecf7FormFetchedError');
  return {
    type: FETCH_SINGLE_CF7_CONTACT_FORM_FAILURE
  };
}

// =============================================================================================
// POST
export const SEND_CF7_CONTACT_FORM_START = 'SEND_CF7_CONTACT_FORM_START';
export const SEND_CF7_CONTACT_FORM_SUCCESS = 'SEND_CF7_CONTACT_FORM_SUCCESS';
export const SEND_CF7_CONTACT_FORM_FAILURE = 'SEND_CF7_CONTACT_FORM_FAILURE';

export const RESET_SEND_TO_CF7_STATUS = 'RESET_SEND_TO_CF7_STATUS';

export type SEND_CONTACT_FORM_7_START_ACTION = {
  type: 'SEND_CF7_CONTACT_FORM_START',
  payload: {
    formInputs: { [key: string]: InputValue },
    id: number
  }
};

export type SEND_CONTACT_FORM_7_FAILURE_ACTION = {
  type: 'SEND_CF7_CONTACT_FORM_FAILURE'
};

export type SEND_CONTACT_FORM_7_SUCCESS_ACTION = {
  type: 'SEND_CF7_CONTACT_FORM_SUCCESS'
};

// reset le statut de l'envoi
export type RESET_SEND_TO_CF7_STATUS_ACTION = {
  type: 'RESET_SEND_TO_CF7_STATUS'
};
export function postContactForm7(
  formInputs: { [key: string]: InputValue },
  id: number
): SEND_CONTACT_FORM_7_START_ACTION {
  return {
    type: SEND_CF7_CONTACT_FORM_START,
    payload: {
      formInputs,
      id
    }
  };
}

export function cf7FormPosted(): SEND_CONTACT_FORM_7_SUCCESS_ACTION {
  return {
    type: SEND_CF7_CONTACT_FORM_SUCCESS
  };
}

export function cf7FormPostError(error: any): SEND_CONTACT_FORM_7_FAILURE_ACTION {
  return {
    type: SEND_CF7_CONTACT_FORM_FAILURE
  };
}

// reset le statut d'envoi du formulaire
export const resetCF7ContactFormStatus = (): RESET_SEND_TO_CF7_STATUS_ACTION => ({
  type: RESET_SEND_TO_CF7_STATUS
});

// fetch un seule formulaire

export type Action =
  | FETCH_CONTACT_FORM_7_START_ACTION
  | FETCH_CONTACT_FORM_7_FAILURE_ACTION
  | FETCH_CONTACT_FORM_7_SUCCESS_ACTION
  | FETCH_SINGLE_CONTACT_FORM_7_START_ACTION
  | FETCH_SINGLE_CONTACT_FORM_7_SUCCESS_ACTION
  | FETCH_SINGLE_CF7_CONTACT_FORM_FAILURE_ACTION
  | FETCH_ALL_CF7_CONTACT_FORM_START_ACTION
  | FETCH_ALL_CF7_CONTACT_FORM_SUCCESS_ACTION
  | FETCH_ALL_CF7_CONTACT_FORM_FAILURE_ACTION
  | SEND_CONTACT_FORM_7_START_ACTION
  | SEND_CONTACT_FORM_7_FAILURE_ACTION
  | SEND_CONTACT_FORM_7_SUCCESS_ACTION
  | RESET_SEND_TO_CF7_STATUS_ACTION;
