// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { FETCH_COMPETITION_START, competitionFetched, competitionFetchError } from 'actions/competitionActions';
import CompetitionsApi from 'api/competition/CompetitionApi';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

export default function(apiExecutor: ApiExecutorType) {
  const competitionsApi = new CompetitionsApi(apiExecutor);

  return function* competitionsSaga(): GeneratorType {
    yield takeLatest(FETCH_COMPETITION_START, fetchCompetition);
  };

  function* fetchCompetition(action): Saga<void> {
    try {
      const { ligue_id, slug } = action.payload;

      let competitionMeta = yield call(competitionsApi.fetchCompetitionMeta, slug);
      if (!competitionMeta.slug) {
        throw new Error('No competition with slug ' + slug);
      }

      const competition = yield call(competitionsApi.fetchCompetitionData, slug, ligue_id);

      yield put(competitionFetched({ ...competitionMeta, ...competition }));
    } catch (e) {
      yield put(competitionFetchError());
    }
  }
}
