// @flow
import { AGREGATOR_URL } from 'constants/api';
import { convertAllMatchesResultsFromApi, convertMatchResultFromApi } from 'services/Match';
import type { ApiExecutorType } from 'types/ApiExecutorType';

function MatchResultApi(executor: ApiExecutorType) {
  return { fetchAllMatchesForMatchOfTheWeek, fetchMatchResultOfTheWeek };

  /**
   * récupére tous les matchs potentiellement de la semaine dans l'agrégateur
   */
  function fetchAllMatchesForMatchOfTheWeek(structure: number, dateMinusAWeek: string, datePlusAWeek: string) {
    const gql = `query {
      Rencontres(
        Structure:{structureParentId:${structure}}, dateDebut: "${dateMinusAWeek}", dateFin: "${datePlusAWeek}") {
          id
          dateEffective
          Etat {nom}
          Competition {
            ClasseAge {
              nom,
              nomWeb,
              Sexe {
                nom
              }
            }
          }
        }
      }`;

    return fetch(AGREGATOR_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: gql
      })
    })
      .then(res => res.json())
      .then(({ data, errors }) => {
        if (errors) {
          throw errors[0];
        }

        return convertAllMatchesResultsFromApi(data.Rencontres);
      });
  }

  /**
   * récupére le match de la semaine dans l'agrégateur
   */
  function fetchMatchResultOfTheWeek(matchId: number) {
    const gql = `query{
      Rencontre(id: ${matchId}) {
        id
        CompetitionEquipeLocale {
          nom
          Structure {
            embleme
          }
          Regroupement {
            embleme
          }
        }
        RencontreResultatLocale {
          pointsDeMarque
        }
        CompetitionEquipeVisiteuse {
          nom
          Structure {
            embleme
          }
          Regroupement {
            embleme
          }
        }
        RencontreResultatVisiteuse {
          pointsDeMarque
        }
        Competition {
          nom
          identifiant
          Saison {
            nom
          }
        }
        Phase {
          nom
        }
        Terrain {
          nom
        }
        dateEffective
        termine
      }
    }`;

    return fetch(AGREGATOR_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: gql
      })
    })
      .then(res => res.json())
      .then(({ data, errors }) => {
        if (errors) {
          throw errors[0];
        }

        return convertMatchResultFromApi(data.Rencontre);
      });
  }
}
export default MatchResultApi;
