// @flow

import type { ClubPreviewType, Practice } from 'types/Club';

export const FETCH_CLUBS_START = 'FETCH_CLUBS_START';
export const FETCH_CLUBS_SUCCESS = 'FETCH_CLUBS_SUCCESS';
export const FETCH_CLUBS_FAILURE = 'FETCH_CLUBS_FAILURE';

export const FETCH_PRACTICES_START = 'FETCH_PRACTICES_START';
export const FETCH_PRACTICES_SUCCESS = 'FETCH_PRACTICES_SUCCESS';
export const FETCH_PRACTICES_FAILURE = 'FETCH_PRACTICES_FAILURE';

export const FETCH_COMPETITIONS_FILTER_START = 'FETCH_COMPETITIONS_FILTER_START';
export const FETCH_COMPETITIONS_FILTER_SUCCESS = 'FETCH_COMPETITIONS_FILTER_SUCCESS';
export const FETCH_COMPETITIONS_FILTER_ERROR = 'FETCH_COMPETITIONS_FILTER_ERROR';

export type FETCH_COMPETITIONS_FILTER_START_ACTION = {
  type: 'FETCH_COMPETITIONS_FILTER_START',
  payload: { ligue: number }
};
export type FETCH_COMPETITIONS_FILTER_SUCCESS_ACTION = {
  type: 'FETCH_COMPETITIONS_FILTER_SUCCESS',
  payload: {
    Competitions: Array<any>,
    Ligue: { nom: string }
  }
};
export type FETCH_COMPETITIONS_FILTER_FAILURE_ACTION = {
  type: 'FETCH_COMPETITIONS_FILTER_ERROR'
};
export type FETCH_PRACTICES_START_ACTION = {
  type: 'FETCH_PRACTICES_START',
  payload: { ligue: number }
};
export type FETCH_PRACTICES_SUCCESS_ACTION = {
  type: 'FETCH_PRACTICES_SUCCESS',
  payload: {
    practices: Array<Practice>
  }
};
export type FETCH_PRACTICES_FAILURE_ACTION = {
  type: 'FETCH_PRACTICES_FAILURE'
};

export type FETCH_CLUBS_START_ACTION = {
  type: 'FETCH_CLUBS_START'
};

export type FETCH_CLUBS_FAILURE_ACTION = {
  type: 'FETCH_CLUBS_FAILURE'
};

export type FETCH_CLUBS_SUCCESS_ACTION = {
  type: 'FETCH_CLUBS_SUCCESS',
  payload: {
    clubs: Array<ClubPreviewType>
  }
};

export type Action =
  FETCH_CLUBS_START_ACTION
  | FETCH_CLUBS_FAILURE_ACTION
  | FETCH_CLUBS_SUCCESS_ACTION
  | FETCH_PRACTICES_FAILURE_ACTION
  | FETCH_PRACTICES_START_ACTION
  | FETCH_PRACTICES_SUCCESS_ACTION
  | FETCH_COMPETITIONS_FILTER_START_ACTION
  | FETCH_COMPETITIONS_FILTER_SUCCESS_ACTION
  | FETCH_COMPETITIONS_FILTER_FAILURE_ACTION
  ;

export function fetchClubs(): FETCH_CLUBS_START_ACTION {
  return {
    type: FETCH_CLUBS_START
  };
}

export function clubsFetched(clubs: Array<ClubPreviewType>): FETCH_CLUBS_SUCCESS_ACTION {
  return {
    type: FETCH_CLUBS_SUCCESS,
    payload: { clubs }
  };
}

export function clubsFetchError(error: Error): FETCH_CLUBS_FAILURE_ACTION {
  return {
    type: FETCH_CLUBS_FAILURE
  };
}

export function fetchCompetitions(ligue: number): FETCH_COMPETITIONS_FILTER_START_ACTION {
  return {
    type: FETCH_COMPETITIONS_FILTER_START,
    payload: { ligue }
  };
}

export function competitionsFetched(data: any): FETCH_COMPETITIONS_FILTER_SUCCESS_ACTION {
  return {
    type: FETCH_COMPETITIONS_FILTER_SUCCESS,
    payload: data
  };
}

export function competitionsFetchError(): FETCH_COMPETITIONS_FILTER_FAILURE_ACTION {
  return {
    type: FETCH_COMPETITIONS_FILTER_ERROR
  };
}

export function fetchPractices(ligue: number): FETCH_PRACTICES_START_ACTION {
  return {
    type: FETCH_PRACTICES_START,
    payload: { ligue }
  };
}

export function practicesFetched(practices: Array<Practice>): FETCH_PRACTICES_SUCCESS_ACTION {
  return {
    type: FETCH_PRACTICES_SUCCESS,
    payload: { practices }
  };
}

export function practicesFetchError(): FETCH_PRACTICES_FAILURE_ACTION {
  return {
    type: FETCH_PRACTICES_FAILURE
  };
}
