// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertTwitterPostsFromApi } from 'services/Social';

function TwitterApi(executor: ApiExecutorType) {
  return { fetchPosts };

  function fetchPosts() {
    return executor.get(`ffr/v1/rs_twitter`)
            .then(responses => {
              const filteredResp = responses.filter(response => !response.retweeted_status);
              return filteredResp.map(resp => convertTwitterPostsFromApi(resp));
            });
  }
}

export default TwitterApi;
