// @flow

import { STATUS_UNKNOWN, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_UNKNOWN, FETCH_PAGE_LIST_START } from 'actions/pageActions';

import type { BlocContent } from 'types/NewsletterBloc';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  title: string,
  excerpt: string,
  titleParent: string,
  description: BlocContent[],
  slug: string,
  label: {
    id: number,
    name: string,
    slug: string
  },
  featured_media: {
    src: string,
    legend: string
  },
  status: Status,
  meta_title: string,
  meta_description: string,
  sidebar_content: BlocContent[],
  template: string,
  albums_filtres: {
    term_id: number,
    name: string,
    slug: string
  }[],
  albums_saisons: {
    term_id: number,
    name: string,
    slug: string
  }[]
};

const initialState: State = {
  title: '',
  excerpt: '',
  titleParent: '',
  description: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Chargement du contenu de la page...'
        }
      ]
    }
  ],
  slug: '',
  label: {
    id: 0,
    name: '',
    slug: ''
  },
  featured_media: {
    src: '',
    legend: ''
  },
  status: STATUS_LOADING,
  meta_title: '',
  meta_description: '',
  template: '',
  sidebar_content: [],
  albums_filtres: [],
  albums_saisons: []
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PAGE_LIST_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_PAGE_LIST_SUCCESS:
      return {
        ...state,
        title: action.payload.pageList.title,
        excerpt: action.payload.pageList.excerpt,
        description: action.payload.pageList.description,
        sidebar_content: action.payload.pageList.sidebar_content,
        slug: action.payload.pageList.slug,
        label: action.payload.pageList.label,
        featured_media: action.payload.pageList.featured_media,
        meta_title: action.payload.pageList.meta_title,
        meta_description: action.payload.pageList.meta_description,
        template: action.payload.pageList.template,
        albums_filtres: action.payload.pageList.albums_filtres,
        albums_saisons: action.payload.pageList.albums_saisons,
        status: STATUS_SUCCESS
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN
      };
    default:
      return state;
  }
}
