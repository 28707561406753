// @flow
import TagManager from 'react-gtm-module';

export function handleTagEvent(
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
  onClickActions?: Function
) {
  return () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel
      },
      dataLayerName: 'dataLayer'
    });

    if (onClickActions) {
      onClickActions();
    }
  };
}

//
// export function getFilterReduced(practices: Array<string>, competitions: Array<string>, distance: string){
//   let reducedPractices = '';
//   let reducedCompetitions = '';
//   if (practices.length > 0) {
//     reducedPractices = practices.reduce((acc, practice) => `${acc}|${practice}`);
//   }
//
//   if (competitions.length > 0) {
//     reducedCompetitions = competitions.reduce((acc, competitions) => `${acc}|${competitions}`);
//   }
//
//   if (reducedPractices !== '' && reducedCompetitions !== '') {
//     if (distance) {
//       return `${reducedPractices}|${reducedCompetitions && reducedCompetitions}|${distance}km`;
//     }
//     return `${reducedPractices}|${reducedCompetitions && reducedCompetitions}`;
//   } else if (reducedPractices !== '') {
//     if (distance) {
//       return `${reducedPractices}|${distance}km`;
//     }
//     return `${reducedPractices}`;
//   } else if (reducedCompetitions !== '') {
//     if (distance) {
//       return `${reducedCompetitions}|${distance}km`;
//     }
//     return `${reducedCompetitions}`;
//   }
// }
