// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';

export type JobListType = {
  title: string,
  content: Array<BlocContent>,
  slug: string,
  link: string,
  city: string,
  region: string,
  type_contrat: string,
  date_cloture: string,
  jobtype: string,
  structure: {
    name: string,
    embleme: string,
  }
};

export type RawJobListType = SEOMetaType & {
  title: {
    raw: string
  },
  content: {
    data: Array<BlocContent>
  },
  slug: string,
  link: string,
  featured_media: {
    src: string,
    legend: string
  },
  meta: {
    structureId: number,
  },
  date_cloture: string,
  region: string,
  type_contrat: string,
  ville: string,
  domaine_emploi: string,
  structure: {
    name: string,
    embleme: string,
  },
  sidebar_content: Array<BlocContent>,
};

export const nullJobList: JobListType = {
  title: '',
  content: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Chargement du contenu de l\'offre...'
        }
      ]
    }
  ],
  slug: '',
  link: '',
  city: '',
  jobtype: '',
  region: '',
  type_contrat: '',
  date_cloture: '',
  structure: {
    name: '',
    embleme: '',
  }
};
