// @flow
import type { RawThemeType, ThemeType, RawQuestionType, QuestionType } from 'types/Faq';
import { nullTheme, nullQuestion } from 'types/Faq';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertThemeFromApi(rawTheme: RawThemeType): ThemeType {
  if (!rawTheme) {
    return nullTheme;
  }

  const { id, description, name, slug } = rawTheme;

  return mergeIntoDefault(nullTheme, {
    id,
    description,
    name,
    slug
  });
}

export function convertQuestionFromApi(rawQuestion: RawQuestionType): QuestionType {
  if (!rawQuestion) {
    return nullQuestion;
  }

  const { id, slug, title, content, theme, label, meta_description, meta_title } = rawQuestion;

  return mergeIntoDefault(nullQuestion, {
    id,
    slug,
    title: title.raw,
    content: content.rendered,
    meta_description,
    meta_title,
    label,
    theme: theme[0]
  });
}
