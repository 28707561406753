// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertSettingsFromApi } from 'services/Settings';

function SettingsApi(executor: ApiExecutorType, staticExecutor: ApiExecutorType) {
  return {
    fetchSettings
  };

  function fetchSettings() {
    return staticExecutor
      .get(`/settings.json`)
      .then(response => convertSettingsFromApi(response))
      .catch(error => {
        return executor.get(`/ffr/v1/settings`).then(response => convertSettingsFromApi(response));
      });
  }
}

export default SettingsApi;
