// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import {
  convertJobListFromApi,
  convertJobDetailFromApi,
  convertJobDetailPreviewFromApi,
  convertJobListFilterFromApi,
} from 'services/Job';
import { FORM_CONTACT_TOKEN } from 'constants/api';
import { convertObjectToQueryString } from 'utils/urlUtils';

function JobApi(executor: ApiExecutorType) {
  return {
    getJobList,
    getJobListFilters,
    fetchJobDetail,
    fetchJobPreviewById,
  };

  async function getJobListFilters() {
    try {
      const domaines = await executor.get('/wp/v2/domaine_emploi?per_page=100')
        .then(response => Promise.all(response.map(convertJobListFilterFromApi)));
      const typesContrats = await executor.get('/wp/v2/type_contrat?per_page=100')
        .then(response => Promise.all(response.map(convertJobListFilterFromApi)));

      return { domaines, typesContrats };
    } catch (e) {
      return new Error(e.message);
    }
  }

  async function getJobList(filters?: Object) {
    const url = `/ffr/v1/emplois${convertObjectToQueryString({ ...filters, per_page: 12 })}`;
    const { body, headers } = await executor.getWithHeader(url);
    const { 'x-wp-totalpages': maxPage, 'x-wp-total': totalJobs } = headers;
    const jobs = await Promise.all(body.map(convertJobListFromApi));

    return { jobs, maxPage, totalJobs };
  }

  function fetchJobDetail(slug: string) {
    return executor.get(`/ffr/v1/emplois?slug=${slug}`).then(response => {
      if (!response || response.length === 0) {
        throw new Error('NOT FOUND');
      }

      return convertJobDetailFromApi(response);
    });
  }

  function fetchJobPreviewById(id: number) {
    return executor.get(`/ffr/v1/emplois/${id}`, {
      headers: {
        Authorization: `Basic ${FORM_CONTACT_TOKEN}`
      }
    }).then(response => convertJobDetailPreviewFromApi(response));
  }
}

export default JobApi;
