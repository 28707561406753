// @flow

export type ThemeType = {
  id: number,
  description: string,
  name: string,
  slug: string
};

export type RawThemeType = {
  id: number,
  description: string,
  name: string,
  slug: string
};

export const nullTheme = {
  id: 0,
  description: '',
  name: '',
  slug: ''
};

export type QuestionType = {
  id: number,
  slug: string,
  title: string,
  content: string,
  meta_description: string,
  meta_title: string,
  theme: number,
  label: {
    id: number,
    name: string,
    slug: string
  },
  link?: string,
  typeFlow: string
};

export type RawQuestionType = {
  id: number,
  slug: string,
  title: {
    raw: string
  },
  content: {
    rendered: string
  },
  meta_description: string,
  meta_title: string,
  theme: Array<number>,
  label: {
    id: number,
    name: string,
    slug: string
  }
};

export const nullQuestion = {
  id: 0,
  slug: '',
  title: '',
  content: '',
  meta_description: '',
  meta_title: '',
  theme: 0,
  label: {
    id: 0,
    name: '',
    slug: ''
  },
  typeFlow: 'QuestionType'
};
