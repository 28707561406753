// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import { FETCH_SEARCH_SUCCESS, FETCH_SEARCH_FAILURE, FETCH_SEARCH_START } from 'actions/searchActions';

import type { ActionType } from 'types/Actions';
import type { ArticleType } from 'types/Article';
import type { EventType } from 'types/Event';
import type { QuestionType } from 'types/Faq';
import type { PageListType } from 'types/PageList';
import type { Status } from 'types/Status';

export type State = {
  articles: Array<ArticleType>,
  events: Array<EventType>,
  pages: PageListType[],
  questions: Array<QuestionType>,
  input: string,
  status: Status
};

const initialState: State = {
  articles: [],
  events: [],
  pages: [],
  questions: [],
  input: '',
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SEARCH_START:
      return {
        ...state,
        input: action.payload.input,
        status: STATUS_LOADING
      };
    case FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        articles: action.payload.search.articles,
        events: action.payload.search.events,
        pages: action.payload.search.pages,
        questions: action.payload.search.questions,
        status: STATUS_SUCCESS
      };
    case FETCH_SEARCH_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
