// @flow

import {
  FETCH_PAGE_NODE_SUCCESS,
  FETCH_PAGE_NODE_START,
  FETCH_PAGE_NODE_FAILURE,
  RESET_PAGE_NODE,
  FETCH_UNKNOWN_PAGE_NODE
} from 'actions/pageActions';
import {
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS,
  STATUS_DEFAULT,
  STATUS_UNKNOWN
} from 'constants/statusConstants';
import type { CardType } from 'types/PageList';
import type { BlocContent } from 'types/NewsletterBloc';
import type { ActionType } from 'types/Actions';
import type { SourceSetType } from 'types/Article';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type State = {
  pageNode: SEOMetaType & {
    id: number,
    title: string,
    titleParent: string,
    description: string,
    liste_enfants: CardType[],
    slug_parent: string,
    slug: string,
    sidebar_content: BlocContent[],
    content: BlocContent[],
    featured_media: {
      src: string,
      legend: string,
      size: SourceSetType
    },
    template: string
  },
  status: string
};

const initialState: State = {
  pageNode: {
    ...nullSeoMeta,
    id: 0,
    title: '',
    titleParent: '',
    description: '',
    sidebar_content: [],
    content: [],
    liste_enfants: [
      {
        menu_order: 0,
        object_id: 0,
        type_name: '',
        object_slug: '',
        title: '',
        description: '',
        featured_media: {
          src: ''
        },
        texte_cta: ''
      }
    ],
    slug_parent: '',
    slug: '',
    featured_media: {
      src: '',
      legend: '',
      size: {}
    },
    template: ''
  },
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PAGE_NODE_START:
      return {
        ...state,
        pageNode: {
          id: action.payload.pageNode.id,
          title: action.payload.pageNode.title,
          titleParent: action.payload.pageNode.titleParent,
          description: action.payload.pageNode.description,
          sidebar_content: action.payload.pageNode.sidebar_content,
          content: action.payload.pageNode.content,
          slug_parent: action.payload.pageNode.slug_parent,
          slug: action.payload.pageNode.slug,
          featured_media: action.payload.pageNode.featured_media,
          liste_enfants: action.payload.pageNode.liste_enfants,
          meta_description: action.payload.pageNode.meta_description,
          meta_title: action.payload.pageNode.meta_title,
          template: action.payload.pageNode.template
        },
        status: STATUS_LOADING
      };
    case FETCH_PAGE_NODE_SUCCESS:
      return {
        ...state,
        pageNode: {
          id: action.payload.pageNode.id,
          title: action.payload.pageNode.title,
          titleParent: action.payload.pageNode.titleParent,
          description: action.payload.pageNode.description,
          sidebar_content: action.payload.pageNode.sidebar_content,
          content: action.payload.pageNode.content,
          slug_parent: action.payload.pageNode.slug_parent,
          slug: action.payload.pageNode.slug,
          featured_media: action.payload.pageNode.featured_media,
          liste_enfants: action.payload.pageNode.liste_enfants,
          meta_description: action.payload.pageNode.meta_description,
          meta_title: action.payload.pageNode.meta_title,
          template: action.payload.pageNode.template
        },
        status: STATUS_SUCCESS
      };
    case FETCH_PAGE_NODE_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case RESET_PAGE_NODE:
      return {
        ...initialState
      };
    case FETCH_UNKNOWN_PAGE_NODE:
      return {
        ...state,
        status: STATUS_UNKNOWN
      };
    default:
      return state;
  }
}
