// @flow
import { MINUTE, HOUR, DAY, MONTH, YEAR } from 'constants/timeConstants';

const monthList = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];

const dayList = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

export function getMonthName(month: number) {
  return monthList[month];
}

export function getMonthNumber(month: string) {
  const monthNumber =
    'janvier___février___mars______avril_____mai_______juin______juillet___août______septembre_octobre___novembre__décembre__'.indexOf(
      month
    ) /
      10 +
    1;

  return monthNumber;
}

export function getDayName(day: number) {
  return dayList[day];
}

export function formatHours(date: Date) {
  return `${date.getHours()}h${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
}

export function formatFullDate(date: Date) {
  const month = parseInt(date.getMonth(), 10) + 1;
  return `${date.getDate()}/${month < 10 ? '0' : ''}${month}/${date.getFullYear()}`;
}

export function formatFullDateForAgregateur(date: Date) {
  const day = parseInt(date.getDate(), 10);
  const month = parseInt(date.getMonth(), 10) + 1;
  return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
}

export function getHoursDifference(date1: Date, date2: Date) {
  return Math.floor(Math.abs(date1 - date2) / 36e5);
}

export function getTimeDifference(date1: Date, date2: Date) {
  const difference = Math.floor(Math.abs(date1 - date2) / 1000);
  let differenceText = '';
  if (Math.floor(difference / YEAR) > 0) {
    const since = Math.floor(difference / YEAR);
    differenceText = `Il y a ${since}a`;
  } else if (Math.floor(difference / MONTH) > 0) {
    const since = Math.floor(difference / MONTH);
    differenceText = `Il y a ${since}m`;
  } else if (Math.floor(difference / DAY) > 0) {
    const since = Math.floor(difference / DAY);
    differenceText = `Il y a ${since}j`;
  } else if (Math.floor(difference / HOUR) > 0) {
    const since = Math.floor(difference / HOUR);
    differenceText = `Il y a ${since}h`;
  } else if (Math.floor(difference / MINUTE) > 0) {
    const since = Math.floor(difference / MINUTE);
    differenceText = `Il y a ${since}min`;
  }

  return differenceText;
}
export function getDateFromString(date: Date | string) {
  return date instanceof Date ? date : new Date(date);
}

export function getFullDate(start: Date, year: boolean = true, day: boolean = true) {
  let date = `${start.getDate()} ${getMonthName(start.getMonth())}`;

  if (day) {
    date = `${getDayName(start.getDay())} ${date}`;
  }

  if (year) {
    date += ` ${start.getFullYear()}`;
  }

  return date;
}
