// @flow

import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import type { BlocFaq, HighLightedPostType, liensRsType, PartnerType } from 'types/Settings';
import type { BlocContent } from 'types/NewsletterBloc';
import type { ActionType } from 'types/Actions';

export type State = {
  ligue: string,
  ligue_id: number,
  title: string,
  description: string,
  url: string,
  logo: string,
  logo_footer: string,
  top_news: {
    title: string,
    link: string,
    label: string
  },
  class_ligue: string,
  titre_liste_cards: string,
  titre_liste_blocs_un: string,
  match_semaine: number,
  blocs_faq: BlocFaq[],
  blocs_trouver_club: BlocFaq[],
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: {
    'android-192': string,
    'android-256': string,
    'apple-touch-icon-152': string,
    'apple-touch-icon-180': string,
    'favicon-16': string,
    'favicon-32': string,
    'mstile-150': string
  },
  liens_rs: liensRsType,
  newsletter: {
    type: false | 'campaign_monitor' | 'lien' | 'mailchimp',
    lien?: string,
    api_key?: string
  },
  cookies: number,
  partenaires: PartnerType[],
  partenaires_page: string,
  partenaires_une_titre: string,
  partenaires_une_description: string,
  message_alerte: {
    raw: string,
    data: BlocContent[]
  },
  home: {
    title: string,
    content: BlocContent[]
  },
  actualite_ffr_vers_ligues: {
    featured_media: Object,
    link: string,
    title: {
      rendered: string,
      raw: string
    },
    date: string,
    label: {
      name: string,
      slug: string
    }
  },
  highlighted_post: HighLightedPostType
};

const initialState: State = {
  ligue: '',
  ligue_id: 0,
  title: '',
  description: '',
  url: '',
  logo: '',
  logo_footer: '',
  top_news: {
    title: '',
    link: '',
    label: ''
  },
  class_ligue: '',
  titre_liste_cards: '',
  titre_liste_blocs_un: '',
  match_semaine: 0,
  blocs_faq: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  blocs_trouver_club: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  contacts: {
    adresse: '',
    adresse_map: '',
    telephone: '',
    email: '',
    horaires: ''
  },
  favicons: {
    'android-192': '',
    'android-256': '',
    'apple-touch-icon-152': '',
    'apple-touch-icon-180': '',
    'favicon-16': '',
    'favicon-32': '',
    'mstile-150': ''
  },
  liens_rs: {
    fb_lien: '',
    instagram_lien: '',
    twitter_lien: '',
    youtube_lien: '',
    flickr_lien: '',
    linkedin_lien: '',
    twitch_lien: '',
    snapchat_lien: '',
    tik_tok_lien: ''
  },
  newsletter: { type: false },
  cookies: 0,
  partenaires: [
    {
      cta: '',
      description: '',
      facebook: '',
      instagram: '',
      lien: '',
      lien_type: '',
      logo: '',
      titre: '',
      twitter: '',
      youtube: ''
    }
  ],
  partenaires_page: '',
  partenaires_une_titre: '',
  partenaires_une_description: '',
  message_alerte: {
    raw: '',
    data: []
  },
  home: {
    title: '',
    content: []
  },
  actualite_ffr_vers_ligues: {
    featured_media: {},
    link: '',
    title: {
      rendered: '',
      raw: ''
    },
    date: '',
    label: {
      name: '',
      slug: ''
    }
  },
  highlighted_post: {
    title: '',
    image: '',
    excerpt: '',
    more_info_link: ''
  }
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        ligue: action.payload.settings.ligue,
        ligue_id: action.payload.settings.ligue_id,
        title: action.payload.settings.title,
        description: action.payload.settings.description,
        url: action.payload.settings.url,
        logo: action.payload.settings.logo,
        logo_footer: action.payload.settings.logo_footer,
        top_news: action.payload.settings.top_news,
        match_semaine: action.payload.settings.match_semaine,
        blocs_faq: action.payload.settings.blocs_faq,
        blocs_trouver_club: action.payload.settings.blocs_trouver_club,
        contacts: action.payload.settings.contacts,
        favicons: action.payload.settings.favicons,
        liens_rs: action.payload.settings.liens_rs,
        newsletter: action.payload.settings.newsletter,
        partenaires: action.payload.settings.partenaires,
        partenaires_page: action.payload.settings.partenaires_page,
        partenaires_une_titre: action.payload.settings.partenaires_une_titre,
        partenaires_une_description: action.payload.settings.partenaires_une_description,
        cookies: action.payload.settings.cookies,
        message_alerte: action.payload.settings.message_alerte,
        home: action.payload.settings.home,
        actualite_ffr_vers_ligues: action.payload.settings.actualite_ffr_vers_ligues,
        highlighted_post: action.payload.settings.highlighted_post
      };
    default:
      return state;
  }
}
