// @flow

import { takeLatest, all } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import { hitTagsArray } from 'constants/hitTagsConstant';
import type { hitTagsArrayType } from 'constants/hitTagsConstant';
import TagManager from 'react-gtm-module';

import { FETCH_ARTICLE_SUCCESS } from 'actions/articleActions';
import { FETCH_ARTICLES_SUCCESS } from 'actions/newsletterActions';
import { FETCH_ARTICLES_CATEGORY_SUCCESS } from 'actions/newsletterCategoryActions';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_PAGE_NODE_SUCCESS, FETCH_PAGE_DETAIL_SUCCESS } from 'actions/pageActions';
import { FETCH_CLUBS_SUCCESS } from 'actions/clubsActions';
import { FETCH_CLUB_SUCCESS } from 'actions/clubActions';
import { FETCH_EVENTS_SUCCESS, FETCH_EVENTS_FAILURE } from 'actions/eventsActions';
import { FETCH_EVENT_SUCCESS } from 'actions/eventActions';
import { SEND_CF7_CONTACT_FORM_START } from 'actions/contactForm7Actions';
import { FETCH_FAQ_THEME_SUCCESS } from 'actions/faqActions';
import { SUBSCRIBE_NEWSLETTER_START } from 'actions/newsletterSubscriptionActions';
import { FETCH_COMPETITIONS_ELEMENTS_SUCCESS } from 'actions/competitionsActions';
import { FETCH_COMPETITION_SUCCESS } from 'actions/competitionActions';

export default function() {
  return function* hitTagsSaga(): GeneratorType {
    yield all(hitTagsArray.map(hitTag => takeLatest(hitTag, throwHitVirtualPages)));
  };

  function* throwHitVirtualPages(action: hitTagsArrayType): Saga<void> {
    try {
      const url = window.location.pathname.split('/');

      switch (action.type) {
        case FETCH_ARTICLE_SUCCESS:
          if (action.payload.analytics) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: action.payload.article.title,
                newsType: action.payload.article.label.name
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_ARTICLES_SUCCESS:
          if (window.location.pathname === '/actualites') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: 'Dernières actualités'
              },
              dataLayerName: 'dataLayer'
            });
          } else if (window.location.pathname === '/') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: 'HomePage'
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_ARTICLES_CATEGORY_SUCCESS:
          if (url[1] === 'actualites') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: `Actualités de la categorie ${action.payload.articles[0].label.name}`
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_PAGE_LIST_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: action.payload.pageList.title
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_PAGE_NODE_SUCCESS:
          if (url.length === 3) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: action.payload.pageNode.title
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_PAGE_DETAIL_SUCCESS:
          if (url.length === 4) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: action.payload.pageDetail.title
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_CLUBS_SUCCESS:
          if (window.location.pathname === '/trouver-un-club-de-rugby/') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: 'Rechercher un club'
              },
              dataLayerName: 'dataLayer'
            });
          } else if (window.location.pathname === '/trouver-un-club-de-rugby/resultats-de-recherche/') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: 'Resultat de la recherche de club'
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_CLUB_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'eventGA',
              eventCategory: 'page club',
              eventAction: 'consultation',
              eventLabel: action.payload.club.name
            },
            dataLayerName: 'dataLayer'
          });
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: action.payload.club.name
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_EVENTS_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: 'Liste des événements'
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_EVENTS_FAILURE:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: 'Liste des événements'
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_EVENT_SUCCESS:
          if (action.payload.analytics) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: `Détail de l'événement ${action.payload.event.title}`
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case SEND_CF7_CONTACT_FORM_START:
          TagManager.dataLayer({
            dataLayer: {
              event: 'eventGA',
              eventCategory: 'contact',
              eventAction: 'formulaire envoye',
              eventLabel: action.payload.formInputs['ffr-subject']
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_FAQ_THEME_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: url[1] === 'faq' ? 'FAQ' : 'Contact'
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case SUBSCRIBE_NEWSLETTER_START:
          TagManager.dataLayer({
            dataLayer: {
              event: 'eventGA',
              eventCategory: 'footer',
              eventAction: 'inscription newsletter',
              eventLabel: action.payload.subscription.CustomFields[0].Value
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_COMPETITIONS_ELEMENTS_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: 'Liste des compétitions'
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_COMPETITION_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: `Détail de la compétition ${action.payload.competition.division}`
            },
            dataLayerName: 'dataLayer'
          });
          break;
        default:
      }
    } catch (e) {}
  }
}
