// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import JobApi from 'api/job/JobApi';
import {
  FETCH_JOB_LIST_START,
  FETCH_JOB_LIST_WITHOUT_FILTER_START,
  jobListFetched,
  jobListFetchError,
  FETCH_JOB_DETAIL_START,
  jobDetailFetched,
  fetchUnknownJobDetail,
  jobDetailFetchError,
  FETCH_JOB_PREVIEWBYID_START,
  jobDetailPreviewFetched,
  jobDetailPreviewFetchError,
  FETCH_JOB_LIST_FILTER_START,
  jobListFiltersFetched
} from 'actions/jobActions';
import type { FETCH_JOB_LIST_START_ACTION } from 'actions/jobActions';

export default function saga(apiExecutor: ApiExecutorType) {
  const jobApi = new JobApi(apiExecutor);

  return function* jobListSaga(): GeneratorType {
    yield takeLatest(FETCH_JOB_LIST_FILTER_START, fetchJobFilters);
    yield takeLatest(FETCH_JOB_LIST_START, initialFetchJobList);
    yield takeLatest(FETCH_JOB_LIST_WITHOUT_FILTER_START, fetchJobList);
    yield takeLatest(FETCH_JOB_DETAIL_START, fetchJobDetail);
    yield takeLatest(FETCH_JOB_PREVIEWBYID_START, fetchJobDetailPreview);
  };

  function* fetchJobFilters(action: FETCH_JOB_LIST_START_ACTION): Saga<void> {
    try {
      const jobFilters = yield call(jobApi.getJobListFilters);
      yield put(jobListFiltersFetched(jobFilters));
    } catch (e) {
      yield put(jobListFetchError());
    }
  }

  function* initialFetchJobList(action: FETCH_JOB_LIST_START_ACTION): Saga<void> {
    try {
      const { filters } = action.payload;
      const { jobs, maxPage, totalJobs } = yield call(jobApi.getJobList, filters);
      const jobFilters = yield call(jobApi.getJobListFilters);
      yield put(jobListFetched(jobs, maxPage, totalJobs, jobFilters));
    } catch (e) {
      yield put(jobListFetchError());
    }
  }

  function* fetchJobList(action: FETCH_JOB_LIST_START_ACTION): Saga<void> {
    try {
      const { filters } = action.payload;
      const { jobs, maxPage, totalJobs } = yield call(jobApi.getJobList, filters);
      yield put(jobListFetched(jobs, maxPage, totalJobs));
    } catch (e) {
      yield put(jobListFetchError());
    }
  }

  function* fetchJobDetail(action): Saga<void> {
    try {
      const { slug } = action.payload;
      const jobDetail = yield call(jobApi.fetchJobDetail, slug);
      yield put(jobDetailFetched(jobDetail, true));
    } catch (e) {
      if (e.message === 'NOT FOUND') {
        yield put(fetchUnknownJobDetail());
      } else {
        yield put(jobDetailFetchError());
      }
    }
  }

  function* fetchJobDetailPreview(action): Saga<void> {
    try {
      const { id } = action.payload;
      const jobDetailPreview = yield call(jobApi.fetchJobPreviewById, id);
      yield put(jobDetailPreviewFetched(jobDetailPreview, true));
    } catch (e) {
      yield put(jobDetailPreviewFetchError());
    }
  }
}
