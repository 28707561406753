// @flow
import type { rawMatchResultType, MatchResultType, MatchForMatchOfTheWeekType } from 'types/MatchResult';
import { nullMatchResult, nullMatchesForMatchOfTheWeek } from 'types/MatchResult';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertAllMatchesResultsFromApi(rawMatchesForMatchOfTheWeek: MatchForMatchOfTheWeekType[]): any {
  if (!rawMatchesForMatchOfTheWeek) {
    return [];
  }

  return rawMatchesForMatchOfTheWeek.map(matchData =>
    mergeIntoDefault(nullMatchesForMatchOfTheWeek, {
      ...matchData,
      id: parseInt(matchData.id, 10)
    })
  );
}

export function convertMatchResultFromApi(rawMatchResult: rawMatchResultType): MatchResultType {
  if (!rawMatchResult) {
    return nullMatchResult;
  }

  const {
    id,
    CompetitionEquipeLocale: {
      nom: equipe_locale,
      Structure: { embleme: embleme_structure_locale },
      Regroupement: regroupement_locale
    },
    RencontreResultatLocale: { pointsDeMarque: score_locale },
    CompetitionEquipeVisiteuse: {
      nom: equipe_visiteuse,
      Structure: { embleme: embleme_structure_visiteuse },
      Regroupement: regroupement_visiteuse
    },
    RencontreResultatVisiteuse: { pointsDeMarque: score_visiteuse },
    Competition: {
      nom: competition,
      identifiant: competition_identifiant,
      Saison: { nom: saison_nom }
    },
    Phase: { nom: phase },
    Terrain: { nom: terrain },
    dateEffective: date,
    termine: statut
  } = rawMatchResult;

  const embleme_locale = embleme_structure_locale
    ? embleme_structure_locale
    : regroupement_locale !== null
    ? regroupement_locale.embleme
    : '';
  const embleme_visiteur = embleme_structure_locale
    ? embleme_structure_visiteuse
    : regroupement_visiteuse !== null
    ? regroupement_visiteuse.embleme
    : '';
  const lien_competitions_ffr = `https://competitions.ffr.fr/${saison_nom}/competitions/${competition_identifiant}/match-${id}.html`;

  return mergeIntoDefault(nullMatchResult, {
    team1: {
      img: embleme_locale,
      name: equipe_locale,
      score: score_locale
    },
    team2: {
      img: embleme_visiteur,
      name: equipe_visiteuse,
      score: score_visiteuse
    },
    competitionType: competition,
    pool: phase,
    place: terrain,
    date: date,
    link: lien_competitions_ffr,
    statut
  });
}
