// @flow

import type { ArticleType } from 'types/Article';
import {nullFailureArticle, nullLoadingArticle} from 'types/Article';

export const FETCH_ARTICLE_START = 'FETCH_ARTICLE_START';
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE';

export type FETCH_ARTICLE_START_ACTION = {
  type: 'FETCH_ARTICLE_START',
  payload: {
    slug: string,
    article: ArticleType
  }
};

export type FETCH_ARTICLE_FAILURE_ACTION = {
  type: 'FETCH_ARTICLE_FAILURE',
  payload: {
    article: ArticleType
  }
};

export type FETCH_ARTICLE_SUCCESS_ACTION = {
  type: 'FETCH_ARTICLE_SUCCESS',
  payload: {
    article: ArticleType,
    analytics: boolean
  }
};

export type Action = FETCH_ARTICLE_START_ACTION | FETCH_ARTICLE_FAILURE_ACTION | FETCH_ARTICLE_SUCCESS_ACTION;

export function fetchArticle(slug: string): FETCH_ARTICLE_START_ACTION {
  return {
    type: FETCH_ARTICLE_START,
    payload: {
      slug: slug,
      article: nullLoadingArticle
    }
  };
}

export function articleFetched(article: ArticleType, analytics?: boolean): FETCH_ARTICLE_SUCCESS_ACTION {
  return {
    type: FETCH_ARTICLE_SUCCESS,
    payload: {
      article,
      analytics: analytics ? analytics : false
    }
  };
}

export function articleFetchError(error: any): FETCH_ARTICLE_FAILURE_ACTION {
  return {
    type: FETCH_ARTICLE_FAILURE,
    payload: {
      article: nullFailureArticle
    }
  };
}
