// https://stackoverflow.com/a/48269720
let Components = {};

Components['CONTACT_FORM_SUCCESS'] = require('./ContactFormSuccessModalContent.js').default;
Components['CONTACT_FORM_FAILED'] = require('./ContactFormFailedModalContent.js').default;
Components['HIGHLIGTED_POST'] = require('./HighlightedPostModalContent.js').default;
Components['NEWSLETTER_MISSING_OPT'] = require('./NewsletterMissingOptModalContent.js').default;
Components['NEWSLETTER_SUBSCRIPTION_SUCCESS'] = require('./NewsletterSubscriptionSuccessModalContent.js').default;
Components['NEWSLETTER_SUBSCRIPTION_FAILED'] = require('./NewsletterSubscriptionFailedModalContent.js').default;

export default Components;
