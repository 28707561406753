//@flow

export const nullMatchResult = {
  team1: {
    img: '',
    name: '',
    score: ''
  },
  team2: {
    img: '',
    name: '',
    score: ''
  },
  competitionType: '',
  link: '',
  pool: '',
  place: '',
  date: '',
  statut: ''
};

export type MatchResultType = {
  team1: {
    img: string,
    name: string,
    score: string
  },
  team2: {
    img: string,
    name: string,
    score: string
  },
  competitionType: string,
  link: string,
  pool: string,
  place: string,
  date: Date | string,
  statut: string
};

export type rawMatchResultType = {
  id: string,
  CompetitionEquipeLocale: {
    nom: string,
    Structure: { embleme: string },
    Regroupement: null | { embleme: string }
  },
  RencontreResultatLocale: { pointsDeMarque: number },
  CompetitionEquipeVisiteuse: {
    nom: string,
    Structure: { embleme: string },
    Regroupement: null | { embleme: string }
  },
  RencontreResultatVisiteuse: { pointsDeMarque: number },
  Competition: {
    nom: string,
    identifiant: string,
    Saison: { nom: string }
  },
  Phase: { nom: string },
  Terrain: { nom: string },
  dateEffective: string,
  termine: boolean | null
};

/**
 * Matches For Match Of The Week
 */
export type MatchForMatchOfTheWeekType = {
  id: number,
  dateEffective: Date,
  Etat: {
    nom: string
  },
  Competition: {
    ClasseAge: {
      nom: string,
      nomWeb: string,
      Sexe: {
        nom: string
      }
    }
  }
};

export const nullMatchesForMatchOfTheWeek = {
  id: 0,
  dateEffective: new Date(),
  Etat: {
    nom: ''
  },
  Competition: {
    ClasseAge: {
      nom: '',
      nomWeb: '',
      Sexe: {
        nom: ''
      }
    }
  }
};
