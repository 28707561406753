// @flow
export function cleanArrayFromDuplicateObjects(array: any[]): any[] {
  let ids = [];

  return array.filter(item => (ids.indexOf(item.id) === -1 ? ids.push(item.id) : false));
}

export function groupBy<T>(elems: Array<T>, keyGetter: (elem: T) => string) {
  return elems.reduce((acc, curr) => {
    const key = keyGetter(curr);

    if (acc[key]) {
      acc[key].push(curr);
    } else {
      acc[key] = [curr];
    }

    return acc;
  }, {});
}
