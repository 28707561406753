// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertPageListFromApi, convertPageNodeFromApi, convertPageDetailFromApi } from 'services/Page';

function PageApi(executor: ApiExecutorType) {
  return {
    fetchPageList,
    fetchPageNode,
    fetchPageDetail
  };

 function fetchPageList(slug: string) {
    return executor.get(`/ffr/v1/pages?slug=${slug}`)
                    .then(response => convertPageListFromApi(response));
  }

 function fetchPageNode(slug: string) {
    return executor.get(`/ffr/v1/pages?slug=${slug}`)
                    .then(response => convertPageNodeFromApi(response));
  }

 function fetchPageDetail(slug: string) {
    return executor.get(`/ffr/v1/pages?slug=${slug}`)
                    .then(response => convertPageDetailFromApi(response));
  }
}

export default PageApi;
