// @flow
// @TODO changer plus tard en fonction du nom des route de submenu
export function getUrlName(name: string): string {
  return name
    .replace(/ /g, '-')
    .replace(/'/g, '-')
    .normalize('NFD')
    .replace(/’/g, '-')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

export function getFileNameFromPath(path: string): string {
  return path.replace(/^.*[\\\/]/, '');
}

export function getGGMapsLink(coordinates: ?number[], address: string): string {
  if (coordinates) {
    return `https://maps.google.com/?ll=${coordinates.join(',')}`;
  }

  return `https://maps.google.com/?q=${address}`;
}

export function convertObjectToQueryString(query?: Object): string {
	let queryString: string = ``;
	if (query) {
		queryString += `?`;
		queryString += Object.keys(query)
			.filter(
				(filterKey) =>
					query &&
					query.hasOwnProperty(filterKey) &&
					(query[filterKey].length > 0 || typeof query[filterKey] === 'number'),
			)
			.map((key) => {
				const value = (query && query.hasOwnProperty(key) && query[key]) || '';

				return `${key}=${value}`;
			})
			.join('&');
	}
	return queryString;
}